<template>
  <div :class="{ message: true, sent }">
    <p class="name">
      {{ message.sender_name }}
    </p>
    <div class="bubble">
      <slot>{{ message.body }}</slot>
    </div>
    <p class="sent-at">
      {{ formatDate(message.created_at) }}
    </p>
  </div>
</template>

<script>
  import { format } from "date-fns";
  export default {
    props: {
      sent: { type: Boolean, default: false },
      message: {
        default: null,
        type: Object,
      },
    },
    data() {
      return {};
    },

    created() {
      const episodeId = this.$store.state.currentEpisodeId;
      if (this.message.read == false && this.message.sender_type == "cobalt") {
        this.$api.put(
          `/self_reporter/episodes/${episodeId}/messages/${this.message.id}`,
          {
            durin_user_id: this.$store.state.userId,
            tenant_code: this.$store.tenantCode,
            read: true,
          }
        );
      }
    },
    methods: {
      formatDate(date) {
        return format(new Date(date), "MM/dd/yyyy hh:mm aa");
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3px;
    width: 100%;
  }

  .bubble {
    display: inline;
    padding: 8px;
    max-width: 90%;
    border-radius: 5px;
    background: #7f7f7f;
    align-self: flex-start;
    color: white;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4);
  }

  .message.sent > .bubble {
    align-self: flex-end !important;
    background: #3b86df;
  }

  .name {
    font-size: 10px;
    margin-bottom: 1px;
  }

  .sent-at {
    font-size: 10px;
  }

  .message.sent > .name {
    align-self: flex-end;
  }

  .message.sent > .sent-at {
    align-self: flex-end;
  }
</style>
