<script setup>
  import { inject } from "vue";
  import { useI18n } from "vue-i18n";
  import { useRoute, useRouter } from "vue-router";

  const { t } = useI18n();
  const router = useRouter();
  const route = useRoute();

  const store = useStore();

  const api = inject("api");

  api.interceptors.request.use((request) => {
    request.headers = store.state.headers;
    return request;
  });

  api.interceptors.response.use(
    (response) => {
      if (route.name !== "Login") {
        store.commit("refreshSessionTimer");
      }

      return response;
    },
    function (error) {
      if ([401, 500].includes(error?.response?.status)) {
        const code =
          error.response["data"]["code"] ||
          error.response["data"]["error"] ||
          error.response["data"]["message"] ||
          "error.generic";

        store.commit("setAuthErrorMessage", { message: t(code) });
        store.commit("logout");

        if (route.name !== "Login") {
          router.replace("/login");
        }
      }
    }
  );
</script>

<template>
  <v-app>
    <Toolbar v-if="isNotLogin" />
    <v-main>
      <router-view />
    </v-main>
    <CustomFooter v-if="isNotLogin" />
    <Chat v-if="showChat" />
    <SessionWarningModal />
    <RefreshNotification />
  </v-app>
</template>

<script>
  import CustomFooter from "@/components/CustomFooter.vue";
  import Toolbar from "@/components/Toolbar.vue";
  import Chat from "@/components/Chat.vue";
  import SessionWarningModal from "@/components/SessionWarningModal.vue";
  import RefreshNotification from "@/components/RefreshNotification.vue";
  import { mapState as mapVuexState, useStore } from "vuex";
  import { mapState } from "pinia";
  import { useSettingsStore } from "@/store/pinia/settings";
  import debounce from "lodash/debounce";

  export default {
    name: "App",

    components: {
      CustomFooter,
      Toolbar,
      Chat,
      SessionWarningModal,
      RefreshNotification,
    },

    data() {
      return {};
    },

    computed: {
      isNotLogin() {
        return this.$route?.name !== "Login";
      },
      ...mapVuexState(["accessToken", "keepAlive"]),
      ...mapState(useSettingsStore, ["settings"]),
      showChat() {
        return this.isNotLogin && this.settings.messaging_enabled;
      },
    },

    watch: {
      accessToken(newValue) {
        if (newValue) {
          this.connectToCable();
        } else {
          this.$cable.connection.disconnect();
          this.$store.commit("cableAction", "disconnect");
        }
      },
    },

    created() {
      if (this.$store.state.accessToken) {
        this.connectToCable();
      }
      document.addEventListener("mouseup", () => {
        if (
          this.$route.name !== "Login" &&
          !this.$store.state.session.warning
        ) {
          this.refreshToken();
        }
      });
    },

    methods: {
      connectToCable() {
        const queryString = new URLSearchParams({
          tenant_code: this.$store.state.tenantCode,
          user_id: this.$store.state.userId,
          token: this.$store.state.accessToken,
        }).toString();

        this.$cable.connection.connect(
          `${import.meta.env.VITE_DURIN_WS_URL}?${queryString}`
        );
        this.$store.commit("cableAction", "connect");
      },
      refreshToken: debounce(function () {
        this.$api.get("/profile");
      }, 10 * 1000),
    },
  };
</script>
