<template>
  <div>
    <label>{{ measurementTypeComponent.readable_name }}</label>
    <v-text-field
      v-model="fieldValue"
      role="textbox"
      :aria-label="`mtc-${measurementTypeComponent.id}`"
      variant="outlined"
      type="number"
      required
      :rules="rules"
      :readonly="!canEdit"
    >
      <template #append-inner>
        <span>{{ suffix }}</span>
      </template>
    </v-text-field>
  </div>
</template>

<script>
  import { existingMeasurement } from "@/mixins/existingMeasurement";
  import { measurementPrecheck } from "@/mixins/measurementPrecheck";

  export default {
    mixins: [existingMeasurement, measurementPrecheck],
    props: {
      measurementTypeComponent: { type: Object, default: null },
      measurementTypeId: { type: Number, default: null },
      canEdit: Boolean,
    },

    data() {
      return {
        concerning: false,
        fieldValue: "",
        databaseUnits: this.measurementTypeComponent.database_units,
        measurementTypeComponentId: this.measurementTypeComponent.id,
        currentEpisodeId: this.$store.state.currentEpisodeId,
        min: this.measurementTypeComponent.min,
        max: this.measurementTypeComponent.max,
        databaseDataType: this.measurementTypeComponent.database_data_type,
        errorMessage: null,
        rules: [
          (value) => !!value || this.$t("rules.required"),
          (value) => parseFloat(value) >= this.min || this.$t("rules.low"),
          (value) => parseFloat(value) <= this.max || this.$t("rules.high"),
          (value) => {
            if (this.databaseDataType === "integer") {
              return /^\d+$/.test(value) || this.$t("rules.integerValid");
            }

            return (
              /^-?\d+(\.\d+)?$/.test(value) || this.$t("rules.numberValid")
            );
          },
        ],
      };
    },

    computed: {
      suffix() {
        return this.measurementTypeComponent.display_units || "";
      },
    },
  };
</script>
