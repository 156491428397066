<script setup>
  import PageTitle from "./PageTitle.vue";
  import Chart from "./charts/Chart.vue";
  import OptionsListChart from "./charts/OptionsListChart.vue";
  import IntakeCombinedChart from "./charts/IntakeCombinedChart.vue";
  import BloodPressureChart from "./charts/BloodPressureChart.vue";
  import { ref, onMounted, inject } from "vue";
  import { useStore } from "vuex";
  import { useI18n } from "vue-i18n";

  const loading = ref(true);
  const chartList = ref([]);
  const errorMessage = ref();
  const hideDashboardReason = ref("");

  const chartComponents = {
    intake_combined: IntakeCombinedChart,
    blood_pressure: BloodPressureChart,
  };

  const api = inject("api");
  const store = useStore();
  const { t } = useI18n();

  function activeMonitoringPeriod() {
    const date = store.getters.measurementStartDate;

    if (!date) {
      hideDashboardReason.value = t("error.beforeMonitoringStartDate");
      return false;
    } else if (Date.parse(date) > Date.now()) {
      hideDashboardReason.value = t("error.beforeMonitoringStartDate");
      return false;
    } else if (chartList.value.length < 1) {
      hideDashboardReason.value = t("error.noSubmittedMeasurements");
      return false;
    } else {
      return true;
    }
  }

  function isOptionsList(chart) {
    return chart.y_axis.labels && Object.keys(chart.y_axis.labels).length > 0;
  }

  function chartable(chart) {
    const unsupportedCharts = [
      "daily_photo_or_video",
      "concerns",
      "photo",
      "video",
    ];

    return unsupportedCharts.indexOf(chart.chart_type) == -1;
  }

  onMounted(() => {
    api
      .get(`/self_reporter/episodes/${store.state.currentEpisodeId}/charts`)
      .then((response) => {
        chartList.value = response.data.charts;
        loading.value = false;
      })
      .catch((e) => {
        errorMessage.value = e.response["data"]["error"];
      });
  });
</script>

<template>
  <v-container>
    <PageTitle :title="$t('titles.insights')" divider />
    <div v-if="loading" align="center">
      <v-progress-circular
        :size="100"
        :width="10"
        color="accent"
        indeterminate
      />
    </div>
    <div v-else>
      <div v-if="activeMonitoringPeriod()">
        <div v-for="(chart, i) in chartList" :key="i">
          <v-card v-if="chart.series.length > 1" class="my-8" color="#F9F9F9">
            <component
              :is="chartComponents[chart.chart_type]"
              :chart-info="chart"
            />
          </v-card>
          <v-card
            v-else-if="isOptionsList(chart)"
            class="my-8"
            color="#F9F9F9"
            elevation="24"
          >
            <OptionsListChart :chart-info="chart" />
          </v-card>
          <v-card
            v-else-if="chartable(chart)"
            class="my-8"
            color="#F9F9F9"
            elevation="24"
          >
            <Chart :chart-info="chart" />
          </v-card>
        </div>
      </div>
      <div v-else>
        {{ hideDashboardReason }}
      </div>
    </div>
  </v-container>
</template>
