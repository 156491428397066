<template>
  <div>
    <span class="text-h6 text-primary-lighten-3" style="word-break: normal">
      {{ name }}
    </span>

    <p class="my-4">{{ intro }}</p>

    <div v-for="prompt in survey.prompts" :key="prompt.id">
      <SurveyPrompt :prompt="prompt" @set-response="setResponse" />
    </div>
    <TaskActions v-if="ready" :complete="complete" @submit="showModal" />
    <SurveyCompletionWarningModal
      v-if="modalOpen"
      @close="closeModal"
      @submit="submit"
    />
  </div>
</template>

<script>
  import TaskActions from "@/components/TaskActions.vue";
  import SurveyPrompt from "@/components/SurveyPrompt.vue";
  import SurveyCompletionWarningModal from "@/components/SurveyCompletionWarningModal.vue";

  export default {
    components: {
      TaskActions,
      SurveyPrompt,
      SurveyCompletionWarningModal,
    },

    props: { task: { type: Object, default: null }, complete: Boolean },

    data() {
      return {
        ready: false,
        errorMessage: null,
        currentEpisodeId: this.$store.state.currentEpisodeId,
        name: "",
        intro: "",
        response: [],
        survey: {},
        answered: [],
        modalOpen: false,
      };
    },

    created() {
      this.$api
        .get(`self_reporter/surveys/${this.task.survey_id}`)
        .then((response) => {
          const survey = response.data.checklist;

          this.survey = survey;
          this.name = survey.name;
          this.intro = survey.intro;
          this.ready = true;
        })
        .catch((e) => {
          this.errorMessage = e.response;
        });
    },

    methods: {
      setResponse(prompt) {
        const index = this.response.findIndex(
          (p) => p.prompt_id === prompt.prompt_id
        );

        if (index >= 0) {
          this.response[index] = prompt;
          if (!this.answered.includes(prompt.prompt_id)) {
            this.answered.push(prompt.prompt_id);
          }
        } else {
          this.response.push(prompt);
        }
      },

      submit() {
        this.modalOpen = false;

        const responseSet = JSON.stringify({
          response_set: {
            checklist_id: this.survey.id,
            task_template_id: this.task.monitoring_task_template_id,
            task_due_date: this.task.due_date,
            name: this.survey.name,
            text: this.survey.intro,
            responses: this.response,
          },
        });

        this.$api
          .post(
            `self_reporter/episodes/${this.currentEpisodeId}/response_sets`,
            responseSet
          )
          .then(() => {
            this.$api
              .post(
                `self_reporter/episodes/${this.currentEpisodeId}/monitoring_task_completions`,
                JSON.stringify({
                  due_date: this.task.due_date,
                  episode_monitoring_task_id:
                    this.task.episode_monitoring_task_id,
                  monitoring_task_template_id:
                    this.task.monitoring_task_template_id,
                })
              )
              .then(() => {
                this.$router.push("/tasks");
              });
          })
          .catch((e) => {
            this.errorMessage = e.response;
          });
      },

      showModal() {
        this.modalOpen = true;
      },

      closeModal() {
        this.modalOpen = false;
      },
    },
  };
</script>
