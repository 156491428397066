<template>
  <v-container>
    <DatePageTitle :title="$t('titles.dashboard')" @change="setDates" />
    <div v-if="loading" class="d-flex align-center justify-center">
      <v-progress-circular
        :size="100"
        :width="10"
        color="accent"
        indeterminate
      />
    </div>
    <div v-else-if="activeMonitoringPeriod()">
      <v-divider class="my-4" />

      <div v-if="isToday">
        <TodaysActivities />
      </div>

      <div v-if="measurements.length < 1">
        {{ $t("error.noExpectedMeasurements") }}
      </div>

      <div v-if="allowedMeasurements().required">
        <div class="d-flex justify-space-between">
          <h6 class="d-flex text-h6 mb-4">
            {{ $t("titles.required") }}
          </h6>
          <h6 class="d-flex text-h6">
            {{
              `${completedRequiredMeasurementsCount}/${totalRequiredMeasurementsCount}`
            }}
          </h6>
        </div>

        <v-slide-group show-arrows>
          <v-slide-group-item
            v-for="(measurement, i) in allowedMeasurements().required"
            :key="i"
          >
            <Measurement
              :required="true"
              :expected-measurement="measurement"
              :existing-measurements="existingMeasurements"
              @update-existing-measurements="updateExistingMeasurements"
            />
          </v-slide-group-item>
        </v-slide-group>
      </div>

      <div v-if="allowedMeasurements().optional" class="mt-12">
        <h6 class="text-h6">
          {{ $t("titles.optional") }}
        </h6>

        <v-slide-group show-arrows>
          <v-slide-group-item
            v-for="(measurement, i) in allowedMeasurements().optional"
            :key="i"
          >
            <Measurement
              :date="startDate"
              :required="false"
              :expected-measurement="measurement"
              :existing-measurements="existingMeasurements"
              @update-existing-measurements="updateExistingMeasurements"
            />
          </v-slide-group-item>
        </v-slide-group>
      </div>
    </div>
    <div v-else>
      {{ hideDashboardReason }}
    </div>
  </v-container>
</template>

<script>
  import { isToday } from "date-fns";
  import { mapState } from "vuex";
  import { useCalendarDatesStore } from "@/store/pinia/calendarDates";
  import { storeToRefs } from "pinia";

  import Measurement from "@/components/Measurement.vue";
  import DatePageTitle from "@/components/DatePageTitle.vue";
  import TodaysActivities from "@/components/TodaysActivities.vue";

  export default {
    components: {
      Measurement,
      DatePageTitle,
      TodaysActivities,
    },
    setup() {
      const store = useCalendarDatesStore();
      const { startDate, endDate } = storeToRefs(store);

      return {
        startDate,
        endDate,
        setStartDate: store.setStartDate,
      };
    },
    data() {
      return {
        loading: false,
        existingMeasurements: {},
        measurements: [],
        errorMessage: null,
        hideDashboardReason: "",
      };
    },
    computed: {
      ...mapState(["currentEpisodeId"]),
      isToday() {
        return isToday(this.startDate);
      },

      completedRequiredMeasurementsCount() {
        const requiredMeasurementsIds = this.allowedMeasurements().required.map(
          ({ measurement_type_id }) => measurement_type_id
        );

        const completedMeasurements = Object.values(this.existingMeasurements)
          .flat()
          .filter(({ measurement_type_id }) =>
            requiredMeasurementsIds.includes(measurement_type_id)
          );

        return completedMeasurements.length;
      },

      totalRequiredMeasurementsCount() {
        return this.allowedMeasurements().required.reduce((prev, curr) => {
          const frequencies = curr.measurement_type_components.map(
            ({ frequency_per_day }) => frequency_per_day
          );

          return prev + Math.max(...frequencies);
        }, 0);
      },
    },
    watch: {
      currentEpisodeId(newValue) {
        if (newValue) {
          this.getExpectedMeasurements(newValue);
        }
      },
    },
    created() {
      if (this.$store.state.currentEpisodeId) {
        this.getExpectedMeasurements(this.$store.state.currentEpisodeId);
      }
    },
    methods: {
      setDates(date) {
        this.setStartDate(date);
        this.getExpectedMeasurements(this.$store.state.currentEpisodeId);
      },

      getExpectedMeasurements(episodeId) {
        this.loading = true;
        this.$api
          .get(`/self_reporter/episodes/${episodeId}/expected_measurements`)
          .then((response) => {
            this.measurements = response.data.expected_measurements.sort(
              (a, b) => a.sequence - b.sequence
            );
            this.loading = false;

            const params = {
              query_start_date: this.startDate.toISOString(),
              query_end_date: this.endDate.toISOString(),
            };

            return this.$api.get(
              `/self_reporter/episodes/${episodeId}/measurements`,
              { params }
            );
          })
          .then((measurementCountResponse) => {
            if (measurementCountResponse) {
              const measurements = measurementCountResponse.data.measurements;
              const result = measurements.reduce(function (h, obj) {
                h[obj.measurement_type_id] = (
                  h[obj.measurement_type_id] || []
                ).concat(obj);
                return h;
              }, {});
              this.existingMeasurements = result;
            }
          })
          .catch((e) => {
            if (e.response) {
              this.errorMessage = e.response["data"]["error"];
            }
          });
      },
      updateExistingMeasurements(key, value) {
        this.existingMeasurements[key] = value;
        // Interestngly if the key already exists it updates but adding a new property
        // does not trigger an update. This is done to update the reference and keep the props reactive.
        this.existingMeasurements = Object.assign(
          {},
          this.existingMeasurements
        );
      },

      activeMonitoringPeriod() {
        const startDate = this.$store.getters.measurementStartDate;
        const endDate = this.$store.getters.measurementEndDate;

        if (!startDate) {
          this.hideDashboardReason = this.$t("error.beforeMonitoringStartDate");
          return false;
        } else if (Date.parse(startDate) > Date.now()) {
          this.hideDashboardReason = this.$t("error.beforeMonitoringStartDate");
          return false;
        } else if (endDate && Date.parse(endDate) < Date.now()) {
          this.hideDashboardReason = this.$t("error.afterMonitoringEndDate");
          return false;
        } else {
          return true;
        }
      },

      frequencyPerDay(measurement) {
        return measurement["measurement_type_components"].reduce(
          (a, b) => a + (b["frequency_per_day"] || 0),
          0
        );
      },

      allowedMeasurements() {
        const grouped = this.measurements.reduce((h, obj) => {
          if (this.frequencyPerDay(obj) > 0) {
            h["required"] = (h["required"] || []).concat(obj);
          } else {
            h["optional"] = (h["optional"] || []).concat(obj);
          }
          return h;
        }, {});

        return grouped;
      },
    },
  };
</script>
