<template>
  <v-radio-group
    v-model="fieldValue"
    :readonly="!canEdit"
    :rules="[(v) => !!v, () => canEdit]"
    required
    color="primary"
  >
    <span class="mb-4 text-primary text-h6">
      {{ measurementTypeComponent.readable_name }}
    </span>
    <div v-for="(item, i) in options" :key="i">
      <v-radio
        color="primary"
        :label="item"
        :value="i.toString()"
        :readonly="!canEdit"
      />
    </div>
  </v-radio-group>
</template>

<script>
  import { existingMeasurement } from "@/mixins/existingMeasurement";
  import { measurementPrecheck } from "@/mixins/measurementPrecheck";

  export default {
    mixins: [existingMeasurement, measurementPrecheck],

    props: {
      measurementTypeComponent: { type: Object, default: undefined },
      measurementTypeId: { type: Number, default: undefined },
      canEdit: { type: Boolean, default: true },
    },

    data() {
      return {
        concerning: false,
        fieldValue: "",
        databaseUnits: this.measurementTypeComponent.database_units,
        measurementTypeComponentId: this.measurementTypeComponent.id,
        currentEpisodeId: this.$store.state.currentEpisodeId,
        options: this.items(),
        errorMessage: null,
      };
    },

    methods: {
      items() {
        return this.measurementTypeComponent.ui_control_elements.split("|");
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
