export const existingMeasurement = {
  created() {
    if (
      this.existingMeasurement &&
      this.existingMeasurement.measurement_values
    ) {
      this.fieldValue = this.existingValue();
    }
  },
  watch: {
    existingMeasurement() {
      this.fieldValue = this.existingValue();
    },
  },
  props: ["existingMeasurement"],
  methods: {
    existingValue() {
      if (
        this.existingMeasurement &&
        this.existingMeasurement.measurement_values
      ) {
        return this.existingMeasurement.measurement_values.find(
          (mv) =>
            mv.measurement_type_component_id ===
            this.measurementTypeComponent.id
        )?.data;
      }
    },
  },
};
