<script setup>
  import { ref } from "vue";
  import { format } from "date-fns";

  const props = defineProps({
    appointment: { type: Object, default: null },
    variant: { type: String, default: "comfortable" },
  });

  const date = new Date(props.appointment.scheduled_at);
  const show = ref(false);

  const hasNotes =
    props.appointment.notes && props.appointment.notes.length > 0;

  const dayOfWeek = format(date, "E");
  const dayOfMonth = format(date, "dd");
  const scheduledTime = format(date, "hh:mm aaa");
</script>

<template>
  <v-list-item class="mb-2 px-2" to="/scheduling">
    <div v-if="variant === 'compact'" class="d-flex flex-sm-grow-1">
      <div class="d-flex align-center flex-sm-grow-1">
        <v-list-item-title class="text-wrap mb-0 pr-4">
          {{ appointment.visit_type }} appointment at {{ scheduledTime }}
        </v-list-item-title>
        <v-list-item-action class="ml-auto">
          <v-icon icon="mdi-calendar" color="primary" />
        </v-list-item-action>
      </div>
    </div>
    <div v-else class="d-flex flex-sm-grow-1">
      <div
        class="d-flex flex-column mr-4 text-center rounded px-4 py-4 datetime-wrapper"
      >
        <span>{{ dayOfWeek }}</span>
        <span class="font-weight-bold text-h5">
          {{ dayOfMonth }}
        </span>
        <span class="text-no-wrap">{{ scheduledTime }}</span>
      </div>
      <div class="d-flex w-100">
        <div>
          <v-list-item-title class="mb-2 appointment-title">
            {{ appointment.visit_type }}
          </v-list-item-title>
          <v-list-item-subtitle class="appointment-subtitle">
            {{ appointment.department }}
          </v-list-item-subtitle>
          <v-expand-transition>
            <div v-show="show" class="mt-2">
              {{ appointment.notes }}
            </div>
          </v-expand-transition>
        </div>
        <div v-if="hasNotes" class="d-flex align-center ml-auto">
          <v-btn icon color="information" variant="text" @click="show = !show">
            <v-icon v-if="show" icon="mdi-chevron-up" />
            <v-icon v-else icon="mdi-chevron-down" />
          </v-btn>
        </div>
      </div>
    </div>
  </v-list-item>
</template>

<style scoped>
  .appointment-title,
  .appointment-subtitle {
    white-space: pre-wrap;
  }

  .text-wrap {
    hyphens: unset;
  }

  .datetime-wrapper {
    height: fit-content;
    background-color: #ddeaf8;
  }
</style>
