<template>
  <v-card class="d-flex flex-column ma-2" width="140">
    <div
      class="d-flex flex-column justify-center align-center text-wrap pt-4 flex-grow-1 text-primary mx-2"
      style="cursor: pointer"
      @click="displayForm"
    >
      <i
        :class="`fa-light fa-2x fa-${expectedMeasurement.measurement_type_icon}`"
      />
      <span class="text-center text-primary mt-2" data-cy="measurement-title">
        {{ measurementReadableName }}
      </span>
    </div>

    <v-card-actions class="align-end">
      <v-btn
        v-if="currentExistingMeasurements.length > 0"
        variant="text"
        icon="mdi-chevron-down"
        class="px-4"
        size="small"
        style="height: 24px"
        data-cy="show-previous-measurements-btn"
        @click="showMeasurements = true"
      />

      <v-spacer />

      <span class="mr-1 d-flex">
        <v-icon
          v-if="hasAlerts"
          icon="mdi-alert-circle"
          color="red"
          class="text-body-1 align-self-center mr-1"
          data-cy="measurement-concerning-icon"
        />
        <v-icon
          v-else-if="!required && measurementCount === 0"
          icon="mdi-circle"
          color="yellow-darken-1"
          class="text-body-1 align-self-center mr-1"
          data-cy="measurement-empty-icon"
        />
        <v-icon
          v-else-if="measurementCount >= frequencyPerDay()"
          icon="mdi-check-circle"
          color="green"
          class="text-body-1 align-self-center mr-1"
          data-cy="measurement-success-icon"
        />
        <v-icon
          v-else
          icon="mdi-circle"
          color="yellow-darken-1"
          class="text-body-1 align-self-center mr-1"
          data-cy="measurement-empty-icon"
        />
        <span v-if="required" style="font-size: 15px; white-space: nowrap">
          {{ measurementCount }}
          /
          {{ frequencyPerDay() }}
        </span>
        <span v-else style="font-size: 15px">
          {{ measurementCount }}
        </span>
      </span>
    </v-card-actions>

    <v-expand-transition>
      <v-card
        v-if="showMeasurements"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 100%"
      >
        <v-list
          v-show="showMeasurements"
          class="py-0"
          style="height: 70%; overflow-y: auto"
        >
          <v-list-item
            v-for="(em, index) in currentExistingMeasurements"
            :key="em.id"
            class="text-wrap"
            @click="setExistingMeasurementIdx(index)"
          >
            <v-divider v-if="index > 0" class="mb-4" />
            <template #prepend>
              <v-icon
                class="mr-2"
                icon="mdi-check-circle-outline"
                color="black"
              />
            </template>
            <v-list-item-title>
              {{ formatDate(em.timestamp) }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn
            variant="text"
            data-cy="hide-previous-measurements-btn"
            icon="mdi-chevron-up"
            size="small"
            class="px-4"
            style="height: 24px"
            @click="showMeasurements = false"
          >
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>

    <v-dialog
      v-model="show"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn class="close" icon @click="hideForm">
            <i class="fa-light fa-xl fa-close" />
          </v-btn>
          <v-toolbar-title>
            {{ measurementReadableName }}
          </v-toolbar-title>
        </v-toolbar>

        <v-container>
          <span class="text-h6 font-weight-regular text-grey">
            {{ expectedMeasurement.measurement_type_prompt }}
          </span>
          <MeasurementForm
            :expected-measurement="expectedMeasurement"
            :existing-measurement="existingMeasurement"
            :existing-measurements="existingMeasurements"
            :existing-measurement-idx="existingMeasurementIdx"
            v-bind="$attrs"
            @hide-form="hideForm"
            @display-form="displayForm"
          />
          <v-img
            :src="settings.health_system_logo_image_url"
            class="mx-auto"
            width="300"
            aspect-ratio="1"
          />
        </v-container>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import { format } from "date-fns";
  import { mapState } from "pinia";
  import { useSettingsStore } from "@/store/pinia/settings";
  import MeasurementForm from "./MeasurementForm.vue";

  export default {
    components: {
      MeasurementForm,
    },

    props: {
      required: Boolean,
      expectedMeasurement: { type: Object, default: null },
      existingMeasurements: { type: Object, default: null },
    },

    data() {
      return {
        hasAlerts: false,
        dialog: false,
        show: false,
        showMeasurements: false,
        displayConcern: false,
        notes: "",
        existingMeasurementIdx: null,
        measurementTypeId: this.expectedMeasurement.measurement_type_id,
        currentEpisodeId: this.$store.state.currentEpisodeId,
        errorMessage: null,
      };
    },

    computed: {
      currentExistingMeasurements() {
        if (this.existingMeasurements) {
          return this.existingMeasurements[this.measurementTypeId] || [];
        }
        return [];
      },

      measurementCount() {
        return this.currentExistingMeasurements.length;
      },

      existingMeasurement() {
        if (this.currentExistingMeasurements.length > 0) {
          return this.currentExistingMeasurements[this.existingMeasurementIdx];
        }

        return null;
      },

      measurementReadableName() {
        return this.expectedMeasurement.measurement_type_readable_name;
      },

      measurementNameLength() {
        return this.expectedMeasurement.measurement_type_readable_name
          .split(" ")
          .sort(function (a, b) {
            return b.length - a.length;
          })[0].length;
      },

      valuesCount() {
        return this.required
          ? `${this.measurementCount()} / ${this.frequencyPerDay()}`
          : this.measurementCount();
      },
      ...mapState(useSettingsStore, ["settings"]),
    },

    watch: {
      currentExistingMeasurements(measurements) {
        const values = measurements.reduce(
          (acc, measurement) => [...acc, ...measurement.measurement_values],
          []
        );
        const concerning = values.reduce(
          (acc, value) =>
            acc ||
            value.is_baseline_concerning ||
            value.is_change_threshold_concerning ||
            value.is_threshold_concerning,
          false
        );

        this.hasAlerts = concerning;
      },

      existingMeasurementIdx(newValue) {
        if (newValue !== null) {
          this.displayForm();
        }
      },

      show(showing) {
        if (!showing) {
          this.showMeasurements = false;

          // make the measurement editable after hiding the dialog
          this.$store.commit(
            `measurement${this.measurementTypeId}/setEditable`
          );
        }
      },
      showMeasurements(newValue) {
        if (!newValue) {
          this.existingMeasurementIdx = null;
        }
      },
    },

    mounted() {
      this.$store.commit("addMeasurement", this.expectedMeasurement);
    },

    methods: {
      onHasAlerts(value) {
        this.hasAlerts = value;
      },

      setExistingMeasurementIdx(idx) {
        this.existingMeasurementIdx = idx;
      },

      formatDate(dateString) {
        return format(new Date(dateString), "hh:mm aa");
      },

      frequencyPerDay() {
        const result = this.expectedMeasurement[
          "measurement_type_components"
        ].map((a) => a.frequency_per_day);

        return Math.max(...result);
      },

      hideForm() {
        this.show = false;
      },

      displayForm() {
        this.show = true;
      },
    },
  };
</script>

<style scoped>
  .tips {
    cursor: pointer;
  }
  .v-card__title {
    height: 110px;
    max-height: 110px;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.25rem;
  }
  .v-card__title span {
    overflow: hidden;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  .v-card__title i {
    width: 100%;
    text-align: center;
    color: #3b86df;
  }
  .v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
  }
  .measurement-title {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
</style>
