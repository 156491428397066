import { createI18n } from "vue-i18n";
import translations from "../translations/translations";

export default createI18n({
  legacy: false,
  locale: window.navigator.userLanguage || window.navigator.language,
  messages: translations,
  fallbackLocale: "en",
  allowComposition: true,
  fallbackWarn: false,
  missingWarn: false,
});
