import { mapState } from "pinia";
import { useSettingsStore } from "@/store/pinia/settings";
import { isAfter, endOfDay, startOfToday, isToday } from "date-fns";
import partition from "lodash/partition";
import orderBy from "lodash/orderBy";

export const externalScheduledVisits = {
  data() {
    return {
      scheduledVisits: [],
      todayScheduledVisits: [],
      pastScheduledVisits: [],
      upcomingScheduledVisits: [],
      loadingExternalScheduledVisits: false,
    };
  },
  computed: {
    ...mapState(useSettingsStore, ["settings"]),
    emptyScheduledVisits() {
      return this.scheduledVisits.length === 0;
    },
  },
  created() {
    if (this.settings.scheduling_view_enabled) {
      this.fetchExternalScheduledVisits();
    }
  },
  methods: {
    async fetchExternalScheduledVisits() {
      this.loading = true;

      try {
        const response = await this.$api.get(
          "/self_reporter/external_scheduled_visits"
        );

        const scheduledVisits = response.data.external_scheduled_visits;

        this.scheduledVisits = scheduledVisits;

        const [upcomingScheduledVisits, pastScheduledVisits] = partition(
          scheduledVisits,
          ({ scheduled_at }) => {
            return isAfter(endOfDay(new Date(scheduled_at)), startOfToday());
          }
        );

        this.todayScheduledVisits = orderBy(
          scheduledVisits.filter(({ scheduled_at }) =>
            isToday(new Date(scheduled_at))
          ),
          "scheduled_at",
          "asc"
        );

        this.upcomingScheduledVisits = orderBy(
          upcomingScheduledVisits,
          "scheduled_at",
          "asc"
        );

        this.pastScheduledVisits = pastScheduledVisits;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }

      this.loading = false;
    },
  },
};
