<script setup>
  const emit = defineEmits(["close", "submit"]);

  function close() {
    emit("close");
  }

  function submit() {
    emit("submit");
  }
</script>

<template>
  <v-dialog :model-value="true" persistent max-width="500px">
    <v-card class="text-center">
      <v-card-title class="text-wrap">
        <span class="text-h6">
          {{ $t("titles.uncompleteSurvey") }}
        </span>
      </v-card-title>

      <p class="pt-4">
        {{ $t("prompts.uncompleteSurvey") }}
      </p>

      <v-card-actions>
        <v-spacer />
        <v-btn variant="text" @click="close">
          {{ $t("buttons.cancel") }}
        </v-btn>
        <v-btn color="primary" class="mr-4 white-text" @click="submit">
          {{ $t("buttons.submit") }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
