<template>
  <v-container>
    <PageTitle :title="$t('scheduling.header')" divider />
    <div v-if="loadingExternalScheduledVisits" align="center">
      <v-progress-circular
        :size="100"
        :width="10"
        color="accent"
        indeterminate
      />
    </div>
    <div v-else-if="emptyScheduledVisits">
      <v-alert
        v-if="disclaimer"
        class="my-4"
        variant="outlined"
        type="info"
        dense
      >
        {{ disclaimer }}
      </v-alert>
      <span>{{ $t("scheduling.empty") }}</span>
    </div>
    <div v-else>
      <v-alert
        v-if="disclaimer"
        class="my-4"
        variant="outlined"
        type="info"
        dense
      >
        {{ disclaimer }}
      </v-alert>
      <h5 class="text-h5 mb-4">
        {{ $t("scheduling.upcomingAppointments") }}
      </h5>
      <div v-if="hasUpcomingVisits">
        <div
          v-for="(appointments, date, i) in upcomingVisitsByMonth"
          :key="date"
        >
          <v-divider v-if="i !== 0" class="mb-4" />
          <span class="pl-0 font-weight-bold text-subtitle-1">
            {{ date }}
          </span>
          <v-list lines="two">
            <div v-for="(appointment, j) in appointments" :key="appointment.id">
              <v-divider v-if="j !== 0" />
              <ExternalAppointment :appointment="appointment" />
            </div>
          </v-list>
        </div>
      </div>
      <span v-else>
        {{ $t("scheduling.emptyUpcomingAppointments") }}
      </span>

      <v-divider class="my-4" />
      <h5 class="text-h5">
        {{ $t("scheduling.pastAppointments") }}
      </h5>
      <div v-if="hasPastVisits">
        <div v-for="(appointments, date) in pastVisitsByMonth" :key="date">
          <span style="font-size: 1rem" class="pl-0 font-weight-bold">
            {{ date }}
          </span>
          <v-list lines="two">
            <div
              v-for="(appointment, index) in appointments"
              :key="appointment.id"
            >
              <v-divider v-if="index !== 0" class="my-4" />
              <ExternalAppointment :appointment="appointment" />
            </div>
          </v-list>
        </div>
      </div>
      <span v-else>
        {{ $t("scheduling.emptyPastAppointments") }}
      </span>
    </div>
  </v-container>
</template>

<script>
  import groupBy from "lodash/groupBy";
  import { mapState } from "pinia";
  import { format } from "date-fns";
  import { useSettingsStore } from "@/store/pinia/settings";

  import { externalScheduledVisits } from "@/mixins/externalScheduledVisits";

  import PageTitle from "@/components/PageTitle.vue";
  import ExternalAppointment from "@/components/ExternalAppointment.vue";

  const groupListByMonth = (list) =>
    groupBy(list, ({ scheduled_at }) =>
      format(new Date(scheduled_at), "MMMM yyyy")
    );

  export default {
    components: {
      PageTitle,
      ExternalAppointment,
    },
    mixins: [externalScheduledVisits],
    computed: {
      ...mapState(useSettingsStore, {
        disclaimer: (store) => store.settings.scheduling_view_disclaimer,
      }),
      hasUpcomingVisits() {
        return this.upcomingScheduledVisits.length > 0;
      },

      hasPastVisits() {
        return this.pastScheduledVisits.length > 0;
      },

      upcomingVisitsByMonth() {
        return groupListByMonth(this.upcomingScheduledVisits);
      },

      pastVisitsByMonth() {
        return groupListByMonth(this.pastScheduledVisits);
      },
    },
  };
</script>
