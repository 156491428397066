import { endOfDay, startOfDay } from "date-fns";
import { ref, computed } from "vue";
import { defineStore } from "pinia";

const replaceDashes = (date: string) => {
  return new Date(date.replace(/-/g, "/"));
};

const today = startOfDay(new Date());
interface MonitoringTask {
  monitoring_condition_id: string;
  monitoring_task_template_id: string;
  short_description: string;
  category: string;
  due_date: string;
  show_if_overdue: boolean;
  completion_window_start_date: string;
  expiration_date: string;
  frequency_per_day: number;
  instructions: string;
  resource_url: string;
  survey_id: string;
  episode_monitoring_task_id: string;
  monitoring_task_completions: Array<MonitoringTaskCompletion>;
}

interface MonitoringTaskCompletion {
  id: string;
  due_date: string;
  created_at: string;
}

export const useTasksStore = defineStore("tasks", () => {
  const tasks = ref<MonitoringTask[]>([]);

  function writeTasks(newTasks: MonitoringTask[]) {
    tasks.value = newTasks;
  }

  const overdue = computed(() =>
    tasks.value.filter((task) => {
      const dueDate = endOfDay(replaceDashes(task.due_date));

      if (task.show_if_overdue && dueDate < today) return true;

      return false;
    })
  );

  const due = computed(() =>
    tasks.value.filter((task) => {
      const dueDate = endOfDay(replaceDashes(task.due_date));
      const complete =
        task.monitoring_task_completions.length >= task.frequency_per_day;

      if (!complete && today < dueDate) return true;

      return false;
    })
  );

  const completed = computed(() =>
    tasks.value.filter((task) => {
      const complete =
        task.monitoring_task_completions.length >= task.frequency_per_day;

      if (complete) return true;

      return false;
    })
  );

  return { tasks, overdue, due, completed, writeTasks };
});
