<template>
  <div>
    <span class="mb-4 subtitle">
      {{ prompt.prompt_text }}
    </span>
    <SingleSelect
      v-if="type == 'single-select'"
      :choices="choices"
      @set-value="setValue"
    />
    <DropDownSingleSelect
      v-if="type == 'drop-down-non-multi'"
      :choices="choices"
      @set-value="setValue"
    />
    <DropDownMultiSelect
      v-if="type == 'drop-down'"
      :choices="choices"
      @set-value="setValue"
    />
    <TextArea
      v-else-if="type == 'text-qn' || type == 'string-qn'"
      @set-value="setValue"
    />
  </div>
</template>

<script>
  import DropDownSingleSelect from "./survey_form_prompt_types/DropDownSingleSelect.vue";
  import DropDownMultiSelect from "./survey_form_prompt_types/DropDownMultiSelect.vue";
  import SingleSelect from "./survey_form_prompt_types/SingleSelect.vue";
  import TextArea from "./survey_form_prompt_types/TextArea.vue";

  export default {
    components: {
      SingleSelect,
      TextArea,
      DropDownMultiSelect,
      DropDownSingleSelect,
    },

    props: {
      prompt: {
        type: Object,
        default: null,
      },
    },

    emits: ["setResponse"],

    data() {
      return {
        choices: this.prompt.prompt_choices,
        type: this.prompt.prompt_type,
        response: {
          prompt_id: this.prompt.id,
          sequence: this.prompt.sequence,
          text: this.prompt.prompt_text,
          value: "",
          note: null,
          reviewed: null,
          follow_up: null,
          escalate: null,
        },
      };
    },

    methods: {
      setValue(value) {
        this.response.value = value;
        this.$emit("setResponse", this.response);
      },
    },
  };
</script>
