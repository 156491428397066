import * as Sentry from "@sentry/vue";
import { startOfDay, subDays } from "date-fns";
import { mapState } from "vuex";
import { mapActions, mapState as pMapState } from "pinia";
import { useTasksStore } from "../store/pinia/tasks";
import { datesHelper } from "./datesHelper";

export const taskQuery = {
  data() {
    return {
      endDate: startOfDay(new Date()).toISOString(),
      startDate: subDays(startOfDay(new Date()), 5).toISOString(),
      loading: false,
      errorMessage: null,
    };
  },

  computed: {
    hasTasks() {
      return this.hasOverdue || this.hasDue;
    },

    hasOverdue() {
      return this.overdueCount > 0;
    },

    hasDue() {
      return this.dueCount > 0;
    },

    hasCompleted() {
      return this.completedCount > 0;
    },

    completedCount() {
      return this.completed.length;
    },

    dueCount() {
      return this.due.length;
    },

    overdueCount() {
      return this.overdue.length;
    },

    ...pMapState(useTasksStore, ["due", "overdue", "completed"]),
    ...mapState(["currentEpisodeId"]),
  },

  methods: {
    ...mapActions(useTasksStore, ["writeTasks"]),
    getMonitoringTasks(episodeId) {
      this.loading = true;

      const params = {
        query_start_date: this.startDate,
        query_end_date: this.endDate,
      };

      this.$api
        .get(`/self_reporter/episodes/${episodeId}/monitoring_tasks`, {
          params,
        })
        .then((response) => {
          const start = new Date(this.startDate);
          const end = new Date(this.endDate);
          const tasks = response.data.monitoring_tasks;

          const filteredTasks = tasks.filter((task) => {
            const dueDate = datesHelper.methods.replaceDashes(task.due_date);
            const incomplete =
              task.monitoring_task_completions.length < task.frequency_per_day;
            const surveyTask = task.category == "Answer";

            return (
              (dueDate >= start && dueDate <= end && incomplete) ||
              dueDate.getTime() == end.getTime() ||
              (surveyTask && incomplete)
            );
          });

          this.writeTasks(filteredTasks);

          this.loading = false;
        })
        .catch((e) => {
          // TODO: check if there is really a response property
          Sentry.captureException(e);
          this.errorMessage =
            e.response["data"]["error"] ?? this.$t("error.generic");
        });
    },
  },
  watch: {
    currentEpisodeId(newValue) {
      if (newValue) {
        this.getMonitoringTasks(newValue);
      }
    },
  },
  created() {
    if (this.$store.state.currentEpisodeId) {
      this.getMonitoringTasks(this.$store.state.currentEpisodeId);
    }
  },
};
