<template>
  <v-snackbar v-model="snackWithButtons" location="bottom" :timeout="timeout">
    {{ snackWithBtnText }}
    <v-btn class="text-white" color="#66CE8B" @click.stop="refreshApp">
      {{ snackBtnText }}
    </v-btn>
    <v-btn icon class="ml-4" color="white" @click="snackWithButtons = false">
      <i class="fa-close" />
    </v-btn>
  </v-snackbar>
</template>

<script>
  export default {
    data() {
      return {
        timeout: -1,
        refreshing: false,
        registration: null,
        snackBtnText: "",
        snackWithBtnText: "",
        snackWithButtons: false,
      };
    },
    created() {
      // Listen for swUpdated event and display refresh snackbar as required.
      document.addEventListener("swUpdated", this.showRefreshUI, {
        once: true,
      });
      // Refresh all open app tabs when a new service worker is installed.
      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          if (this.refreshing) return;
          this.refreshing = true;
          window.location.reload();
        });
      }
    },
    methods: {
      showRefreshUI(e) {
        // Display a snackbar inviting the user to refresh/reload the app due
        // to an app update being available.
        // The new service worker is installed, but not yet active.
        // Store the ServiceWorkerRegistration instance for later use.
        this.registration = e.detail;
        this.snackBtnText = "REFRESH";
        this.snackWithBtnText = "New version available! Refresh now!";
        this.snackWithButtons = true;
      },
      refreshApp() {
        this.snackWithButtons = false;
        // Protect against missing registration.waiting.
        if (!this.registration || !this.registration.waiting) {
          return;
        }
        this.registration.waiting.postMessage("skipWaiting");
      },
    },
  };
</script>
