import { ref } from "vue";
import { defineStore } from "pinia";

export interface Condition {
  name: string;
  faqs: Array<Faq>;
}

export interface Faq {
  question: string;
  answer: string;
  sequence: number;
}

export type StoreCondition = Omit<Faq, "question">;

export const useFaqStore = defineStore("faq", () => {
  const conditions = ref(new Map<string, Map<string, StoreCondition>>());

  function addCondition(condition: Condition) {
    const { name, faqs } = condition;
    const conditionFaqs = new Map(
      faqs.map(({ question, answer, ...rest }) => [
        question,
        { answer, ...rest },
      ])
    );
    conditions.value.set(name, conditionFaqs);
  }

  return { conditions, addCondition };
});
