const state = () => {
  return {
    editable: true,
  };
};

const getters = {
  editable: (state) => state.editable,
};

const mutations = {
  setReadOnly(state) {
    state.editable = false;
  },
  setEditable(state) {
    state.editable = true;
  },
};

export default {
  state,
  getters,
  mutations,
};
