<script setup lang="ts">
  import { computed, ref, onMounted } from "vue";
  import PageTitle from "@/components/PageTitle.vue";
  import { useRouter } from "vue-router";
  import { useFaqStore } from "@/store/pinia/faq";
  import { useI18n } from "vue-i18n";

  const router = useRouter();
  const store = useFaqStore();
  const { t } = useI18n();

  const props = defineProps({
    faqid: { type: String, default: null },
    questionid: { type: String, default: null },
  });

  const question = computed(() =>
    store.conditions?.get(props.faqid)?.get(props.questionid)
  );

  const answer = computed(() => question?.value?.answer);

  const items = ref([
    {
      text: t("titles.faq"),
      disabled: false,
      to: "/faq",
    },
    {
      text: t("titles.question"),
      disabled: true,
      to: "/",
    },
  ]);

  onMounted(() => {
    if (!props || store.conditions.length === 0 || !question.value)
      router.push("/faq");

    if (answer.value)
      document.getElementById("answer").innerHTML = answer.value;
  });
</script>

<template>
  <v-container>
    <PageTitle :title="t('titles.faq')" :divider="false" />
    <v-breadcrumbs :items="items">
      <template #divider>
        <v-icon icon="mdi-chevron-right" />
      </template>
    </v-breadcrumbs>

    <v-card v-if="question" class="mx-auto">
      <v-card-title class="text-h5 text-wrap">
        {{ questionid }}
      </v-card-title>

      <v-card-text class="mt-4">
        <video
          v-if="question.resource_url && question.resource_type === 'video'"
          class="faq-media mb-3"
          controls
          :src="question.resource_url"
        />
        <img
          v-if="question.resource_url && question.resource_type === 'image'"
          class="faq-media mb-3"
          :src="question.resource_url"
        />
        <div class="pl-4 mb-3">
          <a
            v-if="
              question.resource_url && question.resource_type === 'document'
            "
            download
            target="_blank"
            rel="noopener"
            :href="question.resource_url"
          >
            <i class="fa fa-download mr-2" />
            {{ question.resource_url }}
          </a>
        </div>
        <div id="answer" class="pl-4" />
      </v-card-text>

      <v-card-actions>
        <v-btn to="/faq" variant="tonal" color="accent">
          {{ $t("buttons.back") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<style>
  div#answer * {
    padding: inherit;
    margin: inherit;
  }
</style>

<style scoped>
  .faq-media {
    max-width: 100%;
  }
</style>
