<template>
  <div style="max-width: 600px">
    <span class="text-primary text-h6">
      {{ measurementTypeComponent.readable_name }}
      <em v-if="measurementTypeComponent.display_units">
        ({{ measurementTypeComponent.display_units }})
      </em>
    </span>
    <v-slider
      v-model="fieldValue"
      class="my-4"
      :min="min"
      :max="max"
      :disabled="!canEdit"
      thumb-size="32"
      thumb-color="primary"
      track-color="grey"
      :step="1"
    >
      <template #prepend>
        <v-btn
          data-testid="decrement"
          class="mr-4"
          size="small"
          icon="mdi-minus"
          color="success"
          :disabled="!canDecrement"
          @click="decrement"
        />
      </template>

      <template #append>
        <v-btn
          data-testid="increment"
          class="ml-4"
          size="small"
          icon="mdi-plus"
          color="success"
          :disabled="!canIncrement"
          @click="increment"
        />
        <v-btn variant="tonal" class="ml-8" readonly>
          {{ fieldValue }}
        </v-btn>
      </template>
    </v-slider>
  </div>
</template>

<script>
  import { existingMeasurement } from "@/mixins/existingMeasurement";
  import { measurementPrecheck } from "@/mixins/measurementPrecheck";

  export default {
    mixins: [existingMeasurement, measurementPrecheck],

    props: {
      measurementTypeComponent: { type: Object, default: undefined },
      measurementTypeId: { type: Number, default: undefined },
      autofocus: { type: Boolean, default: false },
      canEdit: { type: Boolean, default: false },
    },

    data() {
      return {
        concerning: false,
        fieldValue: 0,
        databaseUnits: this.measurementTypeComponent.database_units,
        measurementTypeComponentId: this.measurementTypeComponent.id,
        currentEpisodeId: this.$store.state.currentEpisodeId,
        errorMessage: null,
        min: this.measurementTypeComponent.min,
        max: this.measurementTypeComponent.max,
      };
    },

    computed: {
      canIncrement() {
        return this.fieldValue < this.max;
      },

      canDecrement() {
        return this.fieldValue > this.min;
      },
    },

    methods: {
      increment() {
        if (this.canIncrement) {
          this.fieldValue++;
        }
      },

      decrement() {
        if (this.canDecrement) {
          this.fieldValue--;
        }
      },
    },
  };
</script>
