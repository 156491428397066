<template>
  <v-container>
    <PageTitle :title="$t('titles.tasks')" />
    <v-breadcrumbs :items="items">
      <template #divider>
        <v-icon icon="mdi-chevron-right" />
      </template>
    </v-breadcrumbs>
    <v-card class="mx-auto">
      <v-card-title
        class="text-h5 text-wrap"
        style="word-break: normal !important"
      >
        {{ task.short_description }}
      </v-card-title>

      <v-card-text v-if="complete" class="text-caption">
        (Completed On:
        {{
          new Date(
            task.monitoring_task_completions[0].created_at
          ).toDateString()
        }})
      </v-card-text>

      <v-card-text v-if="task.instructions && task.category !== 'Answer'">
        <span id="instructions" class="text-medium-emphasis" />
      </v-card-text>

      <v-card-text v-if="task.category == 'Watch' && task.resource_url">
        <video controls="true" :src="task.resource_url" />
      </v-card-text>

      <v-card-text v-if="task.survey_id && !complete">
        <SurveyForm :task="task" :complete="complete" />
      </v-card-text>

      <TaskActions v-else :complete="complete" @submit="submit" />
    </v-card>
  </v-container>
</template>

<script>
  import PageTitle from "./PageTitle.vue";
  import TaskActions from "./TaskActions.vue";
  import SurveyForm from "./SurveyForm.vue";
  import { useTasksStore } from "@/store/pinia/tasks";
  import { useRouter } from "vue-router";

  export default {
    components: {
      PageTitle,
      TaskActions,
      SurveyForm,
    },

    props: {
      id: { type: String, default: null },
      status: { type: String, default: null },
    },

    setup(props) {
      const tasksStore = useTasksStore();

      const router = useRouter();

      const task = tasksStore[props.status][Number(props.id)];

      if (!task) router.push("/");
      return {
        task: tasksStore[props.status][Number(props.id)],
      };
    },

    data() {
      return {
        errorMessage: null,
        currentEpisodeId: this.$store.state.currentEpisodeId,
        items: [
          {
            text: this.$t("titles.tasks"),
            color: "primary",
            disabled: false,
            to: "/tasks",
          },
          {
            text: this.task.short_description,
            disabled: true,
            to: `/tasks/${this.status}/${this.id}`,
          },
        ],
      };
    },

    computed: {
      complete() {
        return this.status === "completed";
      },
    },

    mounted() {
      const instructionsEl = document.getElementById("instructions");

      if (instructionsEl && this.task) {
        instructionsEl.innerHTML = this.task.instructions;
      }
    },

    methods: {
      submit() {
        this.$api
          .post(
            `self_reporter/episodes/${this.currentEpisodeId}/monitoring_task_completions`,
            JSON.stringify({
              episode_monitoring_task_id: this.task.episode_monitoring_task_id,
              due_date: this.task.due_date,
              monitoring_task_template_id:
                this.task.monitoring_task_template_id,
            })
          )
          .then(() => {
            this.$router.push("/tasks");
          })
          .catch((e) => {
            this.errorMessage = e.response;
          });
      },
    },
  };
</script>

<style>
  span#instructions * {
    padding: revert;
    margin: inherit;
  }

  video {
    width: 100%;
    max-width: 800px;
  }
</style>
