<template>
  <div class="chat" :class="{ active: showChat }" style="z-index: 1008">
    <v-card v-if="showChat">
      <v-toolbar class="msg-header" color="primary" dark>
        <v-toolbar-title>{{ $t("titles.messenger") }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="toggleChat">
          <i class="fa-solid fa-close" />
        </v-btn>
      </v-toolbar>
      <v-container class="chat-container py-0">
        <v-row
          id="chat-messages"
          class="d-flex flex-column-reverse flex-nowrap pa-2"
        >
          <ChatMessage
            v-for="msg in messages.slice().reverse()"
            :key="msg.id"
            :sent="msg.sender_type === 'durin'"
            :message="msg"
          />
        </v-row>
        <v-row v-if="settings.messaging_disclaimer" class="chat-disclaimer">
          <v-alert type="info" dense variant="outlined" class="pa-2 ma-2">
            {{ settings.messaging_disclaimer }}
          </v-alert>
        </v-row>
        <v-row class="chat-form">
          <v-col class="py-1 pr-0">
            <v-form
              class="d-flex pt-1 pb-0 align-center"
              @submit.prevent="sendChatMessage()"
            >
              <v-textarea
                v-model="message"
                class="chat-input"
                style="max-height: 10rem; overflow: auto"
                variant="underlined"
                rows="1"
                auto-grow
                :placeholder="$t('placeholders.writeMessage')"
                @keydown.enter.exact.prevent="sendChatMessage()"
              />
              <v-btn
                type="submit"
                class="ml-2"
                icon
                color="success"
                variant="text"
              >
                <i class="fa-xl fa-light fa-paper-plane-top" />
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <div>
      <v-btn
        v-if="!showChat"
        icon
        class="chat-btn"
        color="success"
        @click="toggleChat"
      >
        <v-icon>mdi-message-text</v-icon>
      </v-btn>
      <v-badge
        v-if="numberUnread > 0"
        :content="numberUnread"
        :value="numberUnread"
        color="error"
        bordered
        class="message-alert"
      />
    </div>
  </div>
</template>

<script>
  import * as Sentry from "@sentry/vue";
  import ChatMessage from "@/components/ChatMessage.vue";
  import { useSettingsStore } from "@/store/pinia/settings";
  import { mapState as mapVuexState } from "vuex";
  import { mapState } from "pinia";

  export default {
    components: { ChatMessage },

    data() {
      return {
        messages: [],
        unread: false,
        numberUnread: 0,
        message: "",
        showChat: false,
      };
    },

    computed: {
      ...mapVuexState(["cableConnected"]),
      ...mapState(useSettingsStore, ["settings"], {}),
    },

    watch: {
      cableConnected(newValue) {
        if (newValue) {
          this.$cable.subscribe({
            channel: "ChatChannel",
            episode_id: this.$store.state.currentEpisodeId,
          });
        }
      },
    },
    created() {
      this.getChatMessages();

      if (this.$store.state.cableConnected) {
        this.$cable.subscribe({
          channel: "ChatChannel",
          episode_id: this.$store.state.currentEpisodeId,
        });
      }
    },
    beforeUnmount() {
      if (this.$store.state.cableConnected) {
        this.$cable.unsubscribe("ChatChannel");
      }
    },
    channels: {
      ChatChannel: {
        connected() {
          console.log("connected to chatChannel");
          // TODO: add disclaimer pointing out that
          // you might not recieve messages in real time
        },
        received(data) {
          this.messages.push(data);
          if (!this.showChat) {
            this.unread = true;
            this.numberUnread += 1;
          }
        },
        disconnected: () => null,
      },
    },
    methods: {
      async getChatMessages() {
        const episodeId = this.$store.state.currentEpisodeId;

        try {
          // should look into changing out fetching strategy to something like vue-query
          const response = await this.$api.get(
            `/self_reporter/episodes/${episodeId}/messages`,
            {
              durin_user_id: this.$store.state.userId,
              tenant_code: this.$store.tenantCode,
            }
          );

          this.messages = response?.data?.messages ?? [];

          this.messages.forEach((message) => {
            if (message.sender_type == "cobalt" && message.read == false) {
              this.unread = true;
              this.numberUnread += 1;
            }
          });
        } catch (error) {
          Sentry.captureException(error);
        }
      },
      sendChatMessage() {
        if (this.message.length > 0) {
          const episodeId = this.$store.state.currentEpisodeId;

          this.$api
            .post(`/self_reporter/episodes/${episodeId}/message`, {
              durin_user_id: this.$store.state.userId,
              tenant_code: this.$store.tenantCode,
              body: this.message,
            })
            .then(({ data }) => {
              this.messages.push(data);
              this.message = "";
            });
        }
      },
      toggleChat() {
        this.showChat = !this.showChat;
        this.unread = false;
        this.numberUnread = null;
      },
      color(readness) {
        if (readness) {
          return "error";
        } else {
          return "accent";
        }
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .chat {
    display: flex;
    position: fixed;
    max-width: 300px;
    right: 40px;
    bottom: 30px;
    z-index: 100;
  }

  .chat-disclaimer {
    background-color: rgba(0, 0, 0, 0.03);
  }
  #chat-messages {
    background-color: rgba(0, 0, 0, 0.03);
    max-height: 450px;
    min-height: 300px;
    min-width: 300px;
    overflow-y: auto;
  }

  @media screen and (min-width: 400px) {
    .chat {
      max-width: 400px;
    }
  }

  @media screen and (max-width: 768px) and (orientation: landscape),
    screen and (max-height: 630px) and (orientation: landscape),
    screen and (max-width: 768px) and (orientation: portrait) {
    .chat.active,
    .chat-container,
    .chat > .v-card {
      width: 100%;
    }

    .chat-container {
      height: calc(100% - 72px) /* title height */;
      display: flex;
      flex-direction: column;
      max-width: unset;
    }

    .msg-header {
      position: sticky;
      position: -webkit-sticky;
      z-index: 5;
    }

    .chat-btn,
    .message-alert {
      display: flex;
      position: fixed;
      right: 20px;
      top: 30px;
      z-index: 1005;
    }

    .chat {
      max-width: unset;
      right: 0px;
      bottom: 0px;
      top: 0px;
      margin: 0;
    }

    #chat-messages {
      max-height: unset;
      min-height: unset;
    }

    .chat-form,
    .chat-disclaimer {
      flex: 0 0;
    }
  }

  ::-webkit-scrollbar {
    -webkit-overflow-scrolling: touch;
  }
</style>
