<template>
  <div>
    <highcharts class="chart" :options="chartOptions" />
  </div>
</template>

<script>
  import { useI18n } from "vue-i18n";

  export default {
    props: { chartInfo: { type: Object, default: null } },

    setup() {
      const { t } = useI18n();

      return { t };
    },
    data() {
      return {
        seriesColor: "#6fcd98",
        colorInputIsSupported: null,
        chartOptions: {
          chart: {
            marginRight: 20,
            backgroundColor: "rgba(255, 255, 255, 0)",
            plotBackgroundColor: "rgba(255, 255, 255, .9)",
            plotShadow: true,
            plotBorderWidth: 1,
            zoomType: "x",
            type: "column",
            resetZoomButton: {
              position: {
                x: -10,
                y: -40,
              },
            },
          },
          credits: {
            enabled: false,
          },
          exporting: {
            enabled: false,
          },
          type: "column",
          title: {
            text: this.chartInfo.title,
          },
          series: this.seriesData(),
          subtitle: {
            text: this.chartInfo.subtitle,
          },
          xAxis: {
            type: "datetime",
            dateTimeLabelFormats: {
              year: "%b-%e",
              week: "%b-%e",
              month: "%b-%e",
              day: "%b-%e",
              hour: "%b-%e",
              minute: "%b-%e",
              second: "%b-%e",
              millisecond: "%b-%e",
            },
            labels: {
              format: "{value:%b-%e}",
            },
            minTickInterval: 1,
            minRange: 1209600000, // 2 weeks in milliseconds,
            startOnTick: true,
            endOnTick: true,
          },
          yAxis: {
            allowDecimals: this.allowDecimals(),
            title: {
              text: this.chartInfo.y_axis.title,
            },
            tickInterval: null,
            lineWidth: 1,
            minorTickWidth: 1,
            minorTickLength: 3,
            minorGridLineColor: "#DDDDDD",
            minorTickInterval: "auto",
          },
          tooltip: {
            pointFormat: "{point.notes}",
            crosshairs: true,
            useHTML: true,
          },
          plotOptions: {
            column: {
              stacking: "normal",
            },
            series: {
              marker: {
                enabled: true,
              },
              stickyTracking: false,
            },
          },
        },
      };
    },
    methods: {
      seriesData: function () {
        const series = [];
        this.chartInfo.series.forEach((line) => {
          const dataSeries = [];
          line.data.forEach((plotPoint) => {
            const timestamp = new Date(plotPoint.x);
            const coordinates = { x: timestamp, y: plotPoint.y };
            const notes = [];
            if (plotPoint.point_display_text != null) {
              notes.push(`${plotPoint.point_display_text}<br>`);
            }

            const j = plotPoint.measurements_display_data.length;
            const chunk = 3;
            let subset;

            if (j > 0) {
              notes.push("<table style='padding-top: 10px;'>");
              for (let i = 0; i < j; i += chunk) {
                subset = plotPoint.measurements_display_data.slice(
                  i,
                  i + chunk
                );
                const message = ["<tr>"];
                subset.forEach((displayData) => {
                  message.push(
                    "<td><div style='float:left; width:100%; padding-top: 10px; padding-right:15px'>"
                  );

                  let timeStamp = displayData.timestamp;
                  if (timeStamp != null) {
                    timeStamp = `<b>${timeStamp} </b><br>`;
                    message.push(timeStamp);
                  }

                  const values = displayData.values;
                  if (values != null && values.length > 1) {
                    message.push(values.join("<br>"));
                  } else if (values != null) {
                    message.push(values);
                  }

                  const note = displayData.note;
                  if (note) {
                    message.push(
                      `<br><b>${this.$t("titles.notes")}:</b><br> ${note}`
                    );
                  }
                  message.push("</div></td>");
                });
                message.push("</tr>");
                notes.push(message.join(""));
              }
              notes.push("</table>");
            }

            coordinates["notes"] = notes.join("");

            if (plotPoint.fill_point) {
              coordinates["marker"] = {
                symbol: "circle",
                fillColor: "#5aca81",
              };
            }
            dataSeries.push(coordinates);
          });
          series.push({
            name: line.series_title,
            color: `rgb(${line.color_rgb})`,
            animation: false,
            showInLegend: true,
            data: dataSeries,
          });
        });
        return series;
      },
      allowDecimals: function () {
        if (this.chartInfo.chart_type == "baby_weight_kg") {
          return true;
        } else {
          return false;
        }
      },
    },
  };
</script>

<style scoped></style>
