import { defineStore } from "pinia";
import { ref } from "vue";

import TextWithLabel from "./measurement_form_fields/TextWithLabel.vue";
import Slider from "./measurement_form_fields/Slider.vue";
import Stepper from "./measurement_form_fields/Stepper.vue";
import SelectInput from "./measurement_form_fields/Select.vue";
import OptionsList from "./measurement_form_fields/OptionsList.vue";

type FieldRef =
  | InstanceType<typeof TextWithLabel>
  | InstanceType<typeof Slider>
  | InstanceType<typeof Stepper>
  | InstanceType<typeof SelectInput>
  | InstanceType<typeof OptionsList>;

export const useMeasurementFieldsStore = defineStore(
  "measurementFields",
  () => {
    const fields = ref<{ [key: number]: FieldRef[] }>({});

    function addMeasurementField({ measurementTypeId, field }) {
      const measurementFields = fields.value[measurementTypeId] || [];

      fields.value = {
        ...fields.value,
        [measurementTypeId]: [...measurementFields, field],
      };
    }

    function removeMeasurementField({
      measurementTypeId,
      id,
    }: {
      measurementTypeId: number;
      id: number;
    }) {
      const measurementFields: FieldRef[] = fields[measurementTypeId] ?? [];

      fields.value = {
        ...fields.value,
        [measurementTypeId]: measurementFields.filter(
          (field) => field.id !== id
        ),
      };
    }

    function resetMeasurementFields() {
      fields.value = {};
    }

    return {
      fields,
      addMeasurementField,
      removeMeasurementField,
      resetMeasurementFields,
    };
  }
);
