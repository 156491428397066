<script setup>
  import { ref, inject, computed, onMounted } from "vue";
  import { useFaqStore } from "../store/pinia/faq";
  import PageTitle from "./PageTitle.vue";
  import LoadingOverlay from "./LoadingOverlay.vue";

  const api = inject("api");
  const store = useFaqStore();

  const loading = ref(true);
  const conditions = computed(() => store.conditions);
  const searchValue = ref("");
  const errorMessage = ref();

  const filtered = computed(() => {
    const search = searchValue.value.trim().toLowerCase();

    if (search.length === 0) return [...conditions.value];

    const result = [...conditions.value].reduce(
      (previousConditions, currentCondition) => {
        const [name, faqs] = currentCondition;

        if (name.toLowerCase().includes(search))
          return [...previousConditions, currentCondition];

        const questions = [...faqs].filter(
          ([question, { answer }]) =>
            question.toLowerCase().includes(search) ||
            answer.toLowerCase().includes(search)
        );

        if (questions.length === 0) return previousConditions;

        return [...previousConditions, [name, questions]];
      },
      []
    );

    return result;
  });

  const panelValue = computed(() => {
    return searchValue.value.trim().length > 0
      ? [...Array(filtered.value.length).keys()]
      : undefined;
  });

  onMounted(() => {
    api
      .get(`/faqs?active=true`)
      .then((response) => {
        response?.data?.conditions?.forEach((condition) => {
          store.addCondition(condition);
        });

        loading.value = false;
      })
      .catch((e) => {
        errorMessage.value = e.response["data"]["message"];
      });
  });
</script>

<template>
  <v-container>
    <PageTitle :title="$t('titles.faq')" divider />

    <LoadingOverlay v-if="loading" loading="loading" />

    <div v-else>
      <v-text-field
        v-model="searchValue"
        :label="$t('titles.faqPrompt')"
        variant="outlined"
      />
      <span v-if="filtered.length === 0" class="text-subtitle-1">
        {{ $t("titles.noFAQ") }}
      </span>
      <v-card>
        <v-expansion-panels variant="accordion" :model-value="panelValue">
          <v-expansion-panel
            v-for="[condition, faqs] in filtered"
            :key="condition"
          >
            <v-expansion-panel-title
              expand-icon="mdi-menu-down"
              class="py-4 px-4"
            >
              {{ condition }}
            </v-expansion-panel-title>

            <v-expansion-panel-text>
              <v-list>
                <v-list-item
                  v-for="[question] in faqs"
                  :key="question"
                  :to="{
                    name: 'Question',
                    params: { faqid: condition, questionid: question },
                  }"
                  style="text-decoration: none"
                >
                  <v-list-item-title class="text-wrap" style="hyphens: none">
                    <b class="d-none d-md-inline-block mr-4 text-accent">Q</b>
                    {{ question }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </div>
  </v-container>
</template>
