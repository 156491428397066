import { defineStore } from "pinia";
import { ref } from "vue";

export interface Settings {
  after_hours_clinic_phone: string | null;
  clinic_phone: string | null;
  concerning_measurement_alert: string | null;
  health_system_logo_image_url: string | null;
  health_system_logo_white_image_url: string | null;
  hospital_phone: string | null;
  main_bh_logo_image_url: string | null;
  messaging_disclaimer: string | null;
  messaging_enabled: boolean;
  program_website_name: string | null;
  program_website_url: string | null;
  scheduling_view_disclaimer: string | null;
  scheduling_view_enabled: boolean;
  theme_code: string | null;
  video_chat_enabled: boolean;
}

export const useSettingsStore = defineStore("settings", () => {
  const settings = ref<Settings>({
    after_hours_clinic_phone: null,
    clinic_phone: null,
    concerning_measurement_alert: null,
    health_system_logo_image_url: null,
    health_system_logo_white_image_url: null,
    hospital_phone: null,
    main_bh_logo_image_url: null,
    messaging_disclaimer: null,
    messaging_enabled: false,
    program_website_name: null,
    program_website_url: null,
    scheduling_view_disclaimer: null,
    scheduling_view_enabled: false,
    theme_code: null,
    video_chat_enabled: false,
  });

  function setSettings(newSettings: Settings) {
    settings.value = newSettings;
  }

  return { settings, setSettings };
});
