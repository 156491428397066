<script setup>
  defineProps({ complete: Boolean });
  const emit = defineEmits(["submit"]);

  function submit() {
    emit("submit");
  }
</script>

<template>
  <v-card-actions>
    <v-btn to="/tasks" variant="text" color="accent">
      {{ $t("buttons.back") }}
    </v-btn>
    <v-btn v-if="!complete" color="primary" variant="flat" @click="submit">
      {{ $t("buttons.complete") }}
    </v-btn>
  </v-card-actions>
</template>
