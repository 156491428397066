<template>
  <div>
    <label>{{ $t("titles.notes") }}</label>
    <v-textarea
      v-model="notes"
      variant="outlined"
      rows="4"
      row-height="25"
      :readonly="!canEdit"
      data-cy="measurement-notes"
      @keyup="updateNotes()"
    />
  </div>
</template>

<script>
  export default {
    props: {
      measurementTypeId: { type: Number, default: undefined },
      existingMeasurement: { type: Object, default: undefined },
    },

    emits: ["notesUpdated"],

    data() {
      return {
        notes: "",
        errorMessage: null,
      };
    },

    computed: {
      canEdit() {
        if (!this.existingMeasurement) return true;

        const measurementState =
          this.$store.state[`measurement${this.measurementTypeId}`];

        if (measurementState) return measurementState.editable;

        return true;
      },
    },

    watch: {
      existingMeasurement(newValue) {
        this.notes = (newValue && newValue.notes) || "";
        this.updateNotes();
      },
    },

    created() {
      this.notes =
        (this.existingMeasurement && this.existingMeasurement.notes) || "";
      this.updateNotes();
    },

    methods: {
      updateNotes() {
        this.$emit("notesUpdated", this.notes);
      },
    },
  };
</script>
