<template>
  <v-select
    v-model="fieldValue"
    :items="items()"
    :label="measurementTypeComponent.readable_name"
    :rules="rules"
    :readonly="!canEdit"
    :autofocus="autofocus"
    variant="outlined"
  />
</template>

<script>
  import { existingMeasurement } from "@/mixins/existingMeasurement";
  import { measurementPrecheck } from "@/mixins/measurementPrecheck";

  export default {
    mixins: [existingMeasurement, measurementPrecheck],
    props: {
      measurementTypeComponent: { type: Object, default: undefined },
      measurementTypeId: { type: Number, default: undefined },
      autofocus: { type: Boolean, default: false },
      canEdit: { type: Boolean, default: true },
    },

    data() {
      return {
        fieldValue: "",
        databaseUnits: this.measurementTypeComponent.database_units,
        measurementTypeComponentId: this.measurementTypeComponent.id,
        currentEpisodeId: this.$store.state.currentEpisodeId,
        errorMessage: null,
        rules: [(value) => !!value || this.$t("rules.required")],
      };
    },

    methods: {
      items() {
        return this.measurementTypeComponent.ui_control_elements
          .split("|")
          .map(function (item) {
            return item.trim();
          });
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
