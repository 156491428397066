import { createRouter, createWebHistory } from "vue-router";

import Login from "@/components/Login.vue";
import Dashboard from "@/components/Dashboard.vue";
import Faq from "@/components/Faq.vue";
import TaskList from "@/components/TaskList.vue";
import Task from "@/components/Task.vue";
import Insights from "@/components/Insights.vue";
import VideoCalls from "@/components/VideoCalls.vue";
import Contact from "@/components/Contact.vue";
import Measurement from "@/components/Measurement.vue";
import Question from "@/components/Question.vue";
import Scheduling from "@/components/Scheduling.vue";
import EulaAcceptancePrompt from "@/components/EulaAcceptancePrompt.vue";

import { store } from "./../store";
import { useSettingsStore } from "@/store/pinia/settings";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", name: "Dashboard", component: Dashboard },
    { path: "/login", name: "Login", component: Login },
    { path: "/tasks", name: "TaskList", component: TaskList },
    { path: "/tasks/:status/:id", component: Task, props: true },
    { path: "/faq", name: "FAQ", component: Faq },
    { path: "/contact", name: "Contact", component: Contact },
    {
      path: "/faq/:faqid/question/:questionid",
      name: "Question",
      component: Question,
      props: true,
    },
    { path: "/insights", name: "Insights", component: Insights },
    {
      path: "/video_calls",
      name: "Video Calls",
      component: VideoCalls,
    },
    { path: "/measurement", name: "Measurement", component: Measurement },
    { path: "/eula", name: "EulaAcceptance", component: EulaAcceptancePrompt },
    {
      path: "/scheduling",
      name: "Scheduling",
      component: Scheduling,
    },
  ],
});

router.beforeEach((to, _from, next) => {
  const { settings } = useSettingsStore();

  if (!store.state.accessToken && to.path !== "/login") next({ name: "Login" });
  else if (!store.state.eulaAccepted && !["/eula", "/login"].includes(to.path))
    next({ name: "EulaAcceptance" });
  else if (store.state.eulaAccepted && to.path === "/eula")
    next({ name: "Dashboard" });
  else if (store.state.accessToken && to.path === "/login")
    next({ name: "Dashboard" });
  else if (!settings.video_chat_enabled && to.path === "/video_calls")
    next({ name: "Dashboard" });
  else if (!settings.scheduling_view_enabled && to.path === "/scheduling")
    next({ name: "Dashboard" });
  else next();
});

export { router };
