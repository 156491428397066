<template>
  <v-radio-group
    v-model="value"
    required
    color="primary"
    density="compact"
    @update:model-value="emitValue()"
  >
    <div v-for="(choice, i) in choices" :key="i">
      <v-radio :label="choice.text" :value="choice.text" />
    </div>
  </v-radio-group>
</template>

<script>
  import { responseSets } from "../../mixins/responseSets.js";

  export default {
    mixins: [responseSets],
    props: { choices: { type: Array, default: null } },
    data() {
      return {
        value: "",
      };
    },
  };
</script>
