<template>
  <div>
    <v-file-input
      id="attachment"
      v-model="attachment"
      :accept="accept"
      :label="label"
      :rules="rules"
      :error-messages="errors"
      data-cy="measurement-attachment"
    />
    <div v-if="attachment" class="file-preview">
      <v-img
        v-if="attachmentType.includes('image')"
        :src="attachmentSrc"
        width="400"
      />
      <video
        v-else-if="attachmentType.includes('video')"
        :src="attachmentSrc"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      accept: { type: String, default: "" },
      label: { type: String, default: "" },
      required: { type: Boolean, default: false },
    },

    data() {
      return {
        attachment: null,
        concerning: false,
        currentEpisodeId: this.$store.state.currentEpisodeId,
        errorMessage: null,
        errors: [],
        rules: [
          (value) =>
            !this.required ||
            (value && value.length > 0) ||
            this.$t("rules.required"),
        ],
      };
    },

    computed: {
      attachmentType() {
        return this.attachment.type || [];
      },

      attachmentSrc() {
        return URL.createObjectURL(this.attachment) || null;
      },
    },

    watch: {
      attachment(newVal) {
        if (newVal) this.errors = [];
        if (newVal) this.validateFile(newVal);
      },
    },

    methods: {
      validateFile(file) {
        if (this.accept.includes("video") && file) {
          const video = document.createElement("video");
          video.preload = "metadata";
          video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            const duration = video.duration;

            this.errors = duration > 30 ? [this.$t("rules.video_long")] : [];
          };

          video.src = URL.createObjectURL(file);
        }
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .file-preview {
    padding: 10px;
  }
  .file-preview > img,
  .file-preview > video {
    height: 80px;
    width: auto;
  }
</style>
