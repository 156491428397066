import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";

import "./registerServiceWorker";
import ActionCableVue from "actioncable-vue";
import { store } from "./store";
import { router } from "./router";
import axios from "axios";
import HighchartsVue from "highcharts-vue";
import * as Sentry from "@sentry/vue";
// import pluralize from "vue-pluralize";

import vuetify from "./plugins/vuetify";
import translations from "./plugins/translations";

// components
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const pinia = createPinia();
const app = createApp(App);

// components registration
app.component("DatePicker", DatePicker);

// registering libraries
app.use(router);
app.use(pinia);
app.use(store);
app.use(vuetify);
app.use(translations);
// app.use(pluralize);

const api = axios.create({
  baseURL: import.meta.env.VITE_DURIN_BASE_URL,
});

app.config.globalProperties.$api = api;

// Highcharts.setOptions({
//   lang: {
//     months: i18n.t("highcharts.months"),
//     shortMonths: i18n.t("highcharts.shortMonths"),
//     weekdays: i18n.t("highcharts.weekdays"),
//   },
// });

app.use(HighchartsVue);

app.provide("api", api);

app.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  connectImmediately: false,
});

Sentry.init({
  app,
  environment: import.meta.env.VITE_ENVIRONMENT,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  tracePropagationTargets: ["localhost", /^\//],
  tracesSampleRate: import.meta.env.VITE_SENTRY_SAMPLING_DEFAULT,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
});

Sentry.setTag("LH_ENV", import.meta.env.VITE_ENVIRONMENT);
Sentry.setTag("DATASET_ENV", import.meta.env.VITE_DATASET_ENV);

app.mount("#app");
