<template>
  <div v-if="showTitle" class="mb-4">
    <h6 class="text-h6">
      {{ $t("titles.todaysActivities") }}
    </h6>

    <v-list max-width="500px">
      <VideoCallQuickLink
        v-for="call in callList"
        :key="call.id"
        :call="call"
      />
      <TaskItemQuickLink
        v-for="(task, i) in due"
        :id="i"
        :key="i"
        :task="task"
      />
      <ExternalAppointment
        v-for="appointment in todayScheduledVisits"
        :key="appointment.id"
        variant="compact"
        :appointment="appointment"
      />
      <v-list-item
        v-if="overdue.length > 0"
        class="px-2 rounded"
        :to="`/tasks`"
      >
        <div class="d-flex">
          {{ $t("titles.completeOverdue") }}
        </div>
        <template #append>
          <v-list-item-action end>
            <v-icon icon="mdi-plus-circle" color="primary" />
          </v-list-item-action>
        </template>
      </v-list-item>
    </v-list>

    <v-divider />
  </div>
</template>

<script>
  import { isToday } from "date-fns";
  import { mapState } from "pinia";

  import { datesHelper } from "@/mixins/datesHelper.js";
  import { externalScheduledVisits } from "@/mixins/externalScheduledVisits";
  import { taskQuery } from "@/mixins/taskQuery.js";

  import ExternalAppointment from "@/components/ExternalAppointment.vue";
  import TaskItemQuickLink from "@/components/TaskItemQuickLink.vue";
  import VideoCallQuickLink from "@/components/VideoCallQuickLink.vue";

  import { useSettingsStore } from "@/store/pinia/settings";

  export default {
    components: {
      TaskItemQuickLink,
      VideoCallQuickLink,
      ExternalAppointment,
    },

    mixins: [datesHelper, taskQuery, externalScheduledVisits],

    data() {
      return {
        callList: [],
        errorMessage: null,
      };
    },

    computed: {
      ...mapState(useSettingsStore, {
        videoChatEnabled: (store) => store.settings.video_chat_enabled,
      }),
      showTitle() {
        return (
          this.callList.length > 0 ||
          this.due.length > 0 ||
          this.overdue.length > 0 ||
          this.todayScheduledVisits.length > 0
        );
      },
    },

    created() {
      if (this.videoChatEnabled) {
        this.$api
          .get(
            `/self_reporter/episodes/${this.$store.state.currentEpisodeId}/video_calls`
          )
          .then((response) => {
            response.data.video_calls.forEach((call) => {
              const complete = call.status == "complete";
              const today = isToday(new Date(call.start_time));

              if (today && !complete) {
                this.callList.push(call);
              }
            });
          })
          .catch((e) => {
            this.errorMessage = e.response["data"]["error"];
          });
      }
    },
  };
</script>
