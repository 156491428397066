<template>
  <v-dialog v-model="show" persistent max-width="500px">
    <v-card class="text-center">
      <v-card-text class="text-subtitle-1">
        <span class="text-h6">
          {{ $t("titles.sessionWarning") }}
        </span>
      </v-card-text>
      <v-card-text class="pt-4 text-medium-emphasis">
        {{ $t("prompts.sessionWarning") }}
      </v-card-text>
      <v-card-actions>
        <v-btn block @click="refreshSession">
          {{ $t("buttons.continue") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { useI18n } from "vue-i18n";

  export default {
    setup() {
      const { t } = useI18n();

      return { t };
    },

    data() {
      return {
        timeout: null,
      };
    },

    computed: {
      show() {
        return this.$store.state.session.warning;
      },
    },

    watch: {
      show: function (newShow) {
        // on open give the user 60 seconds to prevent logout,
        // we'll fire a request instead of manually logging them out
        if (newShow) {
          this.timeout = setTimeout(() => {
            localStorage.clear();
            this.$store.commit("setAuthErrorMessage", {
              message: this.t("error.sessionExpired"),
            });
            this.$store.commit("logout");
            this.$router.push("/login");
          }, 60 * 1000);
        }
      },
    },

    methods: {
      refreshSession() {
        // making a request will trigger the refreshsession
        // store action in the interceptor, this will close the modal
        clearTimeout(this.timeout);
        this.$api.get("/profile");
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .v-card__text,
  .v-card__title {
    word-break: normal !important;
  }
</style>
