<template>
  <v-select
    v-model="value"
    :items="items()"
    variant="outlined"
    @update:model-value="emitValue()"
  />
</template>

<script>
  import { responseSets } from "../../mixins/responseSets.js";

  export default {
    mixins: [responseSets],
    props: { choices: { type: Array, default: null } },
    data() {
      return {
        value: "",
      };
    },
    methods: {
      items() {
        return this.choices.map((choice) => choice.text);
      },
    },
  };
</script>
