<script setup lang="ts">
  import { useSettingsStore } from "@/store/pinia/settings";
  import { storeToRefs } from "pinia";

  const { settings } = storeToRefs(useSettingsStore());
</script>

<template>
  <v-footer app absolute color="primary" class="d-flex flex-column px-0 py-0">
    <div class="w-100 d-flex flex-column align-center justify-center py-2">
      <span>
        {{ $t("footer.support") }}
        <a class="text-white" href="tel:1-800-921-4324"> 1-800-921-4324 </a>
      </span>

      <span>{{ $t("footer.emergency") }}</span>
    </div>
    <div
      class="w-100 d-flex align-center justify-center py-2 bg-primary-lighten-1"
    >
      <a target="_blank" href="https://www.locushealth.com">
        <v-img
          :src="settings.main_bh_logo_image_url"
          aspect-ratio="1"
          class="mx-auto"
          width="30px"
          alt="locus logo"
        />
      </a>
    </div>
  </v-footer>
</template>
