export const responseSets = {
  mounted: function () {
    this.emitValue();
  },

  methods: {
    emitValue() {
      this.$emit("setValue", this.value);
    },
  },
};
