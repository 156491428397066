<script setup>
  import { useSettingsStore } from "@/store/pinia/settings";
  import { storeToRefs } from "pinia";
  import PageTitle from "./PageTitle.vue";

  const { settings } = storeToRefs(useSettingsStore());
</script>

<template>
  <v-container class="d-flex flex-column justify-center">
    <PageTitle :title="$t('titles.contact')" divider />

    <v-card class="card-container mx-auto">
      <v-card-text v-if="settings.program_website_name" class="text-center">
        <h5 class="text-subtitle-1 font-weight-bold">
          {{ $t("contact.website") }}
        </h5>
        <a
          :href="settings.program_website_url"
          style="text-decoration: none"
          target="_blank"
          color="primary"
        >
          {{ settings.program_website_name }}
        </a>
      </v-card-text>

      <v-card-text v-if="settings.clinic_phone" class="text-center">
        <h5 class="text-subtitle-1 font-weight-bold">
          {{ $t("contact.clinicPhone") }}
        </h5>
        <span>
          {{ settings.clinic_phone }}
        </span>
      </v-card-text>

      <v-card-text v-if="settings.hospital_phone" class="text-center">
        <h5 class="text-subtitle-1 font-weight-bold">
          {{ $t("contact.hospitalPhone") }}
        </h5>
        <span>
          {{ settings.hospital_phone }}
        </span>
      </v-card-text>
      <v-card-text v-if="settings.after_hours_clinic_phone" class="text-center">
        <h5 class="text-subtitle-1 font-weight-bold">
          {{ $t("contact.afterHours") }}
        </h5>

        <span>
          {{ settings.after_hours_clinic_phone }}
        </span>
      </v-card-text>
      <v-card-text class="text-center">
        <span class="text-body-1 font-italic font-weight-medium text-accent">
          {{ $t("footer.support") }}
          <a href="tel:1-800-921-4324">1-800-921-4324</a>
        </span>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style scoped>
  .card-container {
    width: 50%;
  }

  @media only screen and (max-width: 960px) {
    .card-container {
      width: 100%;
    }
  }

  a,
  a:visited,
  a:hover,
  a:active {
    color: rgb(var(--v-theme-primary));
  }
</style>
