<template>
  <v-container>
    <PageTitle :title="$t('titles.videoCalls')" divider />

    <div v-if="loading" align="center">
      <v-progress-circular
        :size="100"
        :width="10"
        color="accent"
        indeterminate
      />
    </div>

    <v-card v-for="(call, i) in callList" :key="i" class="my-4">
      <v-row class="ma-0">
        <v-col>
          <v-card-title class="pa-0 call_subject">
            {{ call.subject }}
          </v-card-title>
        </v-col>
        <v-col align-self="center" class="text-right">
          <v-chip :color="getStatusColor(call.status)" variant="flat">
            {{ $t(`call.status.${call.status}`) }}
          </v-chip>
        </v-col>
      </v-row>

      <v-card-actions class="flex justify-end pa-4">
        <v-row class="ma-0 pa-0">
          <v-col cols="12" class="text-grey pa-0">
            Hosted by: {{ call.scheduled_by }}
          </v-col>
          <v-col cols="12" class="text-grey pa-0">
            {{ $t("labels.callScheduled") }}:
            {{ formatDate(call.start_time) }}
          </v-col>
          <v-col cols="12" class="text-grey pa-0">
            {{ $t("labels.callDuration") }}:
            {{ call.duration_in_minutes }} minutes
          </v-col>
        </v-row>
        <v-btn
          variant="flat"
          color="success"
          :disabled="!isCallToday(call)"
          class="align-self-end"
          @click="navigateToCall(call)"
        >
          {{ $t("buttons.join") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import { isToday } from "date-fns";
  import { format } from "date-fns-tz";

  import PageTitle from "./PageTitle.vue";

  export default {
    components: { PageTitle },

    data() {
      return {
        loading: true,
        callList: [],
        errorMessage: null,
      };
    },

    created() {
      this.$api
        .get(
          `/self_reporter/episodes/${this.$store.state.currentEpisodeId}/video_calls`
        )
        .then((response) => {
          this.callList = response.data.video_calls;
          this.loading = false;
        })
        .catch((e) => {
          this.errorMessage = e.response["data"]["error"];
        });
    },

    methods: {
      formatDate(date) {
        return format(new Date(date), "MM/dd/yyyy hh:mm aa zzz");
      },

      getStatusColor(status) {
        const statusClass = {
          not_started: "warning",
          completed: "success",
          in_progress: "grey-lighten-2",
        };

        return statusClass[status] || "default";
      },

      isCallToday(call) {
        return isToday(new Date(call.start_time));
      },

      navigateToCall(call) {
        window.open(call.url, "_blank");
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .call_subject {
    overflow-wrap: break-word;
    word-break: break-word;
  }
</style>
=
