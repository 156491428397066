<template>
  <v-container>
    <PageTitle :title="$t('titles.tasks')" divider />

    <div v-if="loading" align="center">
      <v-progress-circular
        :size="100"
        :width="10"
        color="accent"
        indeterminate
      />
    </div>

    <div v-else-if="hasTasks">
      <v-card class="my-4">
        <v-list v-if="hasOverdue" lines="three">
          <v-list-subheader>{{ $t("titles.overdue") }}</v-list-subheader>

          <v-list-item
            v-for="(task, i) in overdue"
            :key="i"
            :value="i"
            :to="`/tasks/overdue/${i}`"
            :data-cy="`/tasks/overdue/${i}`"
          >
            <template #prepend>
              <v-list-item-action start>
                <v-checkbox-btn readonly :model-value="false" />
              </v-list-item-action>
            </template>

            <v-list-item-title class="text-wrap text-primary">
              {{ task.short_description }} ({{
                $t(`tasks.category.${task.category}`)
              }})
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ $t("labels.dueOn") }}:
              {{ task.due_date }}
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>

        <v-list v-if="hasDue || hasCompleted" lines="three">
          <v-list-subheader>{{ $t("titles.due") }}</v-list-subheader>

          <v-list-item
            v-for="(task, i) in due"
            :key="i"
            :value="i"
            :to="`/tasks/due/${i}`"
            :data-cy="`/tasks/due/${i}`"
          >
            <template #prepend>
              <v-list-item-action start>
                <v-checkbox-btn readonly :model-value="false" />
              </v-list-item-action>
            </template>

            <v-list-item-title class="text-wrap text-primary">
              {{ task.short_description }} ({{
                $t(`tasks.category.${task.category}`)
              }})
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ $t("labels.dueOn") }}:
              {{ task.due_date }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item
            v-for="(task, i) in completed"
            :key="i"
            :value="i"
            :to="`/tasks/completed/${i}`"
            :data-cy="`/tasks/completed/${i}`"
          >
            <template #prepend>
              <v-list-item-action start>
                <v-checkbox-btn color="green" readonly :model-value="true" />
              </v-list-item-action>
            </template>

            <v-list-item-title class="text-wrap text-primary">
              {{ task.short_description }} ({{
                $t(`tasks.category.${task.category}`)
              }})
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ $t("labels.dueOn") }}:
              {{ task.due_date }}
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
    <div v-else>
      {{ $t("error.tasks") }}
    </div>
  </v-container>
</template>

<script>
  import { datesHelper } from "@/mixins/datesHelper.js";
  import { taskQuery } from "@/mixins/taskQuery.js";

  import PageTitle from "@/components/PageTitle.vue";

  export default {
    components: {
      PageTitle,
    },

    mixins: [datesHelper, taskQuery],
  };
</script>
