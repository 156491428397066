<template>
  <div>
    <v-app-bar color="primary">
      <v-app-bar-nav-icon
        variant="text"
        data-cy="nav_menu"
        @click.stop="drawer = !drawer"
      />
      <v-badge
        v-if="notifications > 0"
        style="cursor: pointer"
        color="red"
        icon="mdi-bell"
        offset-x="8"
        offset-y="-13"
        left
        :value="notifications"
      />

      <v-toolbar-title> Locus </v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" color="grey-lighten-4">
      <v-list-item>
        <a target="_blank" :href="settings.program_website_url">
          <v-img
            data-cy="logo"
            width="200"
            aspect-ratio="2.2"
            :src="settings.health_system_logo_image_url"
          />
        </a>
      </v-list-item>

      <v-divider />

      <v-list nav>
        <v-list-item link to="/" prepend-icon="mdi-home">
          {{ $t("titles.dashboard") }}
        </v-list-item>

        <v-list-item link to="/faq" prepend-icon="mdi-help">
          {{ $t("titles.faq") }}
        </v-list-item>

        <v-list-item link to="/tasks" prepend-icon="mdi-check">
          {{ $t("titles.tasks") }}
        </v-list-item>

        <v-list-item
          link
          to="/insights"
          prepend-icon="mdi-chart-bell-curve-cumulative"
        >
          {{ $t("titles.insights") }}
        </v-list-item>

        <v-list-item
          v-if="settings.video_chat_enabled"
          link
          to="/video_calls"
          prepend-icon="mdi-video"
        >
          {{ $t("titles.videoCalls") }}
          <template #append>
            <VideoCallNotification @set-count="setNotificationIcon" />
          </template>
        </v-list-item>

        <v-list-item
          v-if="settings.scheduling_view_enabled"
          link
          to="/scheduling"
          prepend-icon="mdi-home"
        >
          {{ $t("titles.scheduling") }}
        </v-list-item>

        <v-list-item link to="/contact" prepend-icon="mdi-phone">
          {{ $t("titles.contact") }}
        </v-list-item>

        <v-list-item prepend-icon="mdi-logout" @click="logout">
          {{ $t("titles.logout") }}
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-dialog v-model="contact" max-width="500px">
      <v-card />
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions } from "pinia";
  import { useSettingsStore } from "@/store/pinia/settings";
  import VideoCallNotification from "./VideoCallNotification.vue";

  export default {
    components: {
      VideoCallNotification,
    },

    data() {
      return {
        contact: false,
        drawer: null,
        notifications: null,
        errors: [],
      };
    },

    computed: {
      ...mapState(useSettingsStore, ["settings"]),
    },

    created() {
      this.$api
        .get(`/app_instructions`)
        .then((response) => {
          this.setSettings(response.data);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    methods: {
      ...mapActions(useSettingsStore, ["setSettings"]),
      setNotificationIcon(count) {
        this.notifications = count;
      },

      logout() {
        this.$store.commit("logout");
        this.$router.push("/login");
      },
    },
  };
</script>
