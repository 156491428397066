<script setup>
  import { format } from "date-fns-tz";

  defineProps({ call: { default: null, type: Object } });

  function formatDate(date) {
    return format(new Date(date), "hh:mm aa zzz");
  }
</script>

<template>
  <v-list-item target="_blank" :href="call.url" class="px-2 rounded">
    <v-list-item-title class="text-wrap">
      {{ call.subject }}
      {{ $t("labels.callScheduled") }}
      {{ formatDate(call.start_time) }}
    </v-list-item-title>
    <template #append>
      <v-list-item-action end>
        <v-icon icon="mdi-video-outline" color="primary" />
      </v-list-item-action>
    </template>
  </v-list-item>
</template>
