<template>
  <v-container
    class="d-flex flex-column fill-height align-center justify-center container"
  >
    <div class="w-100 mb-4">
      <v-alert
        v-if="!!authErrorMessage"
        density="compact"
        variant="outlined"
        type="error"
      >
        {{ authErrorMessage }}
      </v-alert>
    </div>

    <v-card align="center" class="elevation-24 w-100">
      <v-form ref="form" v-model="isFormValid" @submit.prevent="submit">
        <v-toolbar color="primary" dense>
          <div class="ml-4">
            <v-img :src="logo" width="48" aspect-ratio="1" alt="locus logo" />
          </div>
          <v-toolbar-title class="text-left">Locus</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-text-field
            v-model="login"
            type="text"
            :label="$t('labels.login')"
            :rules="loginRules"
            name="login"
            prepend-inner-icon="mdi-account"
            placeholder=" "
            required
          />

          <v-text-field
            id="password"
            v-model="password"
            required
            :rules="passwordRules"
            :label="$t('labels.password')"
            name="password"
            prepend-inner-icon="mdi-lock"
            type="password"
          />
        </v-card-text>

        <v-card-actions class="d-flex flex-column flex-sm-row">
          <v-spacer />
          <v-btn
            variant="text"
            class="text-primary forgot-password"
            :href="forgotPasswordURL"
          >
            {{ $t("buttons.forgotPassword") }}
          </v-btn>
          <v-btn
            type="submit"
            color="primary white--text"
            :disabled="!isFormValid"
            :loading="loading"
          >
            {{ $t("buttons.login") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
  import { useSettingsStore } from "@/store/pinia/settings";
  import { mapActions, mapState } from "pinia";

  const headers = {
    "Content-Type": "application/json",
    "Accept-Language": "en",
    "Access-Control-Allow-Origin": "*",
  };

  export default {
    data() {
      return {
        valid: true,
        loading: false,
        isFormValid: false,
        login: "",
        loginRules: [
          (v) => !!v || this.$t("rules.loginRequired"),
          (v) => /.+@.+\..+|^\d{10,12}$/.test(v) || this.$t("rules.loginValid"),
        ],
        password: "",
        passwordRules: [(v) => !!v || this.$t("rules.passwordRequired")],
        forgotPasswordURL: import.meta.env.VITE_FORGOT_PASSWORD_URL,
      };
    },

    computed: {
      ...mapState(useSettingsStore, {
        logo(store) {
          return store.settings.main_bh_logo_image_url;
        },
      }),
      authErrorMessage() {
        return this.$store.getters.authErrorMessage;
      },
    },

    created() {
      this.$api
        .get(`/app_instructions`, {
          headers: headers,
        })
        .then((response) => {
          this.setSettings(response.data);
        })
        .catch(() => null);
    },

    methods: {
      ...mapActions(useSettingsStore, ["setSettings"]),
      errorMessage() {
        return this.$store.getters.authErrorMessage;
      },
      submit() {
        this.loading = true;
        this.$api
          .post(`/current_user`, {
            login: this.login,
            password: this.password,
            headers: headers,
          })
          .then((response) => {
            localStorage.setItem(
              "accessToken",
              response.data["authentication_token"]
            );
            localStorage.setItem("tenantCode", response.data["tenant_code"]);
            localStorage.setItem(
              "programCode",
              response.data["monitoring_program_code"]
            );
            localStorage.setItem(
              "login",
              response.data["email"] || response.data["phone_number"]
            );
            localStorage.setItem(
              "name",
              response.data["first_name"] + " " + response.data["last_name"]
            );
            localStorage.setItem("userId", response.data["id"]);
            this.$store.commit("login");
          })
          .then(this.fetchEpisodeInfo)
          .then(this.fetchEulaAcceptanceStatus)
          .catch(() => {
            this.loading = false;
            const passwordInput = this.$refs.form.items.find(
              (item) => item.id === "password"
            );
            passwordInput.reset();
            this.$store.commit("logout");
          });
      },

      fetchEulaAcceptanceStatus() {
        return this.$api.get(`/profile`).then((response) => {
          this.errorMessage = null;
          if (response.data.eula.accepted) {
            this.$store.commit("eulaAccepted");
            this.$router.push("/");
          } else {
            this.$store.commit("eulaNotAccepted");
            this.$router.push("/eula");
          }
        });
      },
      fetchEpisodeInfo() {
        return this.$api
          .get(`/self_reporter/episodes?active=true`)
          .then((response) => {
            if (response.data["episodes"].length < 1) {
              this.$store.commit("setAuthErrorMessage", {
                message: "There are no active episodes for this account",
              });

              throw new Error("There are no active episodes");
            } else {
              localStorage.setItem(
                "currentEpisodeId",
                response.data["episodes"][0]["id"]
              );
              localStorage.setItem(
                "measurementStartDate",
                response.data["episodes"][0]["measurement_start_date"]
              );
              localStorage.setItem(
                "measurementEndDate",
                response.data["episodes"][0]["monitoring_end_date"]
              );
              this.$store.commit("setCurrentEpisodeInfo");
            }
          });
      },
    },
  };
</script>

<style scoped>
  .container {
    width: 50%;
  }

  @media only screen and (max-width: 960px) {
    .container {
      width: 100%;
    }
  }
</style>
