<template>
  <v-textarea
    v-model="value"
    variant="outlined"
    rows="4"
    row-height="25"
    @keyup="emitValue()"
  />
</template>

<script>
  import { responseSets } from "../../mixins/responseSets.js";

  export default {
    mixins: [responseSets],
    data() {
      return {
        value: "",
      };
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
