<template>
  <v-select
    v-model="value"
    :items="items()"
    multiple
    variant="outlined"
    @update:model-value="emitValue()"
  />
</template>

<script>
  import { responseSets } from "../../mixins/responseSets.js";

  export default {
    mixins: [responseSets],

    props: { choices: { type: Array, default: null } },

    emits: ["setValue"],

    data() {
      return {
        value: [],
      };
    },

    methods: {
      emitValue() {
        this.$emit("setValue", this.value.join(", "));
      },

      items() {
        return this.choices.map((choice) => choice.text);
      },
    },
  };
</script>
