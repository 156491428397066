<template>
  <v-badge
    v-if="value > 0"
    inline
    color="red"
    :content="content"
    :value="value"
  >
    <slot />
  </v-badge>
</template>

<script>
  import { isToday } from "date-fns";

  export default {
    emits: ["setCount"],

    data() {
      return {
        content: null,
        value: null,
      };
    },

    created() {
      this.$api
        .get(
          `/self_reporter/episodes/${this.$store.state.currentEpisodeId}/video_calls`
        )
        .then((response) => {
          let callList = response.data.video_calls;
          callList = callList.filter((call) => {
            return isToday(new Date(call.start_time));
          });
          this.content = callList.length;
          this.value = callList.length;
          this.emitCount();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    methods: {
      emitCount() {
        this.$emit("setCount", this.value);
      },
    },
  };
</script>
