<template>
  <v-dialog
    v-model="unaccepted"
    persistent
    width="auto"
    max-width="80%"
    max-height="90%"
  >
    <v-card>
      <div v-if="!eula" align="center">
        <v-progress-circular
          :size="100"
          :width="10"
          color="accent"
          indeterminate
        />
      </div>
      <v-card-text
        id="eula"
        class="text-subtitle-1 text-medium-emphasis content"
      />
      <div class="d-flex justify-end mr-4 mb-4">
        <v-btn @click="accept">
          {{ $t("buttons.accept") }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data() {
      return {
        unaccepted: !this.$store.state.eulaAccepted,
        eula: "",
      };
    },

    created() {
      this.$api
        .get(`/eula`)
        .then((response) => {
          this.eula = response.data.eula.body;
          document.getElementById("eula").innerHTML = this.eula;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    methods: {
      accept() {
        this.$api
          .put(`/profile`, {
            eula: {
              accepted: true,
            },
          })
          .then(() => {
            this.errorMessage = null;
            this.$store.commit("eulaAccepted");
            this.$router.push("/");
          })
          .catch((e) => {
            this.errorMessage = e.response["data"]["message"];
            this.$store.commit("logout");
          });
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  div#eula :deep(p) {
    margin-bottom: 10px;
  }
</style>
