import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { endOfDay, startOfToday } from "date-fns";

export const useCalendarDatesStore = defineStore("calendarDates", () => {
  const startDate = ref(startOfToday());
  const endDate = computed(() => endOfDay(startDate.value));

  function setStartDate(date: Date) {
    startDate.value = date;
  }

  return { startDate, endDate, setStartDate };
});
