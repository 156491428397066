<script setup lang="ts">
  import { ref, watch, computed } from "vue";
  import { startOfDay, subDays, max, format } from "date-fns";
  import PageTitle from "@/components/PageTitle.vue";
  import { useCalendarDatesStore } from "@/store/pinia/calendarDates";
  import { useStore } from "vuex";

  defineProps({ title: { type: String, required: true } });
  const { startDate } = useCalendarDatesStore();
  const store = useStore();
  const minStartDate = new Date(
    `${store.getters.measurementStartDate}T00:00:00`
  );

  const emit = defineEmits(["change"]);
  const date = ref(startDate);

  const dateFormat = "EEEE MMM dd, yyyy";
  const minDate = max([subDays(startOfDay(new Date()), 5), minStartDate]);
  const maxDate = startOfDay(new Date());

  const dateDisplayed = computed(() => format(date.value, dateFormat));

  watch(date, (newDate) => {
    emit("change", newDate);
  });
</script>

<template>
  <PageTitle :title="title">
    <DatePicker
      v-model="date"
      class="w-auto align-self-center"
      auto-apply
      :clearable="false"
      prevent-min-max-navigation
      :min-date="minDate"
      :max-date="maxDate"
      :enable-time-picker="false"
      data-cy="calendar-btn"
    >
      <template #trigger>
        <v-btn
          block
          color="primary"
          class="text-center font-weight-regular"
          prepend-icon="mdi-calendar-blank"
        >
          {{ dateDisplayed }}
        </v-btn>
      </template>
    </DatePicker>
  </PageTitle>
</template>

<style scoped>
  .title-container {
    margin-bottom: 20px;
  }

  input.dp__pointer.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg {
    text-align: center;
  }

  .title-text {
    align-self: flex-end;
  }

  @media screen and (max-width: 500px) {
    .title-text {
      align-self: center;
    }

    .title-container {
      flex-direction: column;
    }
    .title-text {
      margin-bottom: 0.2em;
    }
  }
</style>
